import React from "react";
import sponser1 from "../../images/sponsors-1.png";
import sponser2 from "../../images/sponsors-2.png";
import sponser3 from "../../images/sponsors-3.png";
import sponser4 from "../../images/sponsors-4.png";
import "./SponserDesc.css";
import { LazyLoadImage } from "react-lazy-load-image-component";

export const SponserDescription = () => {
  return (
    <>
      <section className="sponsors-intro">
        <div className="container spacer-y pb-0 text-center">
          <div className="row justify-content-center text-center">
            <div className="col-lg-8">
              <p className="heading-md fw-600">Our Amazing Sponsors</p>
              <p className="para-1 muted-text">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s,
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="sponsors-wrapper">
        <div className="container spacer-y pb-0">
          <div className="row g-3 gx-lg-4">
            <div className="col-lg-6">
              <div className="spnsr-card">
                <LazyLoadImage
                  src={sponser1}
                  className="img-fluid mb-4"
                  alt="sponser"
                />
                <h4 className="mb-3">Strike Force Energy</h4>
                <p className="muted-text mb-3">
                  Strike Force Energy packets go great in beer, cocktails,
                  bottled water, or hell, just drink the things straight up LIKE
                  A BEAST.Enter our special promo code “SmokePit” for a
                  discount!
                </p>
                <p className="mb-4">
                  <span className="fw-600">Flavors:</span>{" "}
                  <span className="muted-text">
                    Orignal / Orange / Grape /Lemon
                  </span>
                </p>
                <a name="shop now" href="https://www.strikeforceenergy.com/" target="_blank" className="theme-button">
                  Shop Now
                </a>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="spnsr-card">
                <LazyLoadImage
                  src={sponser2}
                  className="img-fluid mb-4"
                  alt="sponser"
                />
                <h4 className="mb-3">Grunt Style</h4>
                <p className="muted-text mb-3">
                  Grunt Style’s vision is to deliver the highest quality, most
                  patriotic apparel, and we have over 400 US Veterans and
                  Patriots making that happen. Our GS Custom program allows for
                  you to stand out and support First Responders and the Grunt
                  style community.
                </p>
                <a name="shop now" href="https://www.gruntstyle.com/" target="_blank" className="theme-button">
                  Shop Now
                </a>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="spnsr-card">
                <LazyLoadImage
                  src={sponser3}
                  className="img-fluid mb-4"
                  alt="sponser"
                />
                <h4 className="mb-3">Aspetto</h4>
                <p className="muted-text mb-3">
                  Tech to Tactical – Aspetto Has You Covered. Our mission began
                  in 2008 with the purpose of providing end-to-end support of
                  life protection and national security infrastructure.
                  Headquartered in Fredericksburg, VA., Aspetto is an 8(a)/
                  HUBZone certified, product and technology services consulting
                  firm specializing in delivering strategic enterprise
                  life-cycle support for the acquisition, design, deployment and
                  sustainment of technology and life protection components from
                  the network to the battlefield.
                </p>
                <a name="shop now" href="https://www.aspetto.com/#/" target="_blank" className="theme-button">
                  Learn More
                </a>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="spnsr-card">
                <LazyLoadImage
                  src={sponser4}
                  className="img-fluid mb-4"
                  alt="sponser"
                />
                <h4 className="mb-3"> Eclpst</h4>
                <p className="muted-text mb-3">
                  Eclpst is a digital media company that is veteran owned and
                  specializes in helping businesses achieve new levels of
                  success through various online mediums. Their goal is to help
                  you increase your online presence, increase brand awareness,
                  help you grow your businesses to achieve greater success
                </p>
                <p className="mb-4">
                  <span className="fw-600">They offer services in:</span>
                  <span className="muted-text">
                    Website Design / Graphic Design(Branding, Advertising,
                    Creative Content)Social Media Marketing / SEO /
                    MarketingPrinting / Hosting & / So much more!
                  </span>
                </p>
                <a name="shop now" href="https://eclpst.com/" target="_blank" className="theme-button">
                  Check em Out
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
