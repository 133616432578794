import React from "react";
import { Footer } from "../../Components/Footer/Footer";
import { Resource } from "../../Components/Resource/Resource";
import { Sponser } from "../../Components/Sponser/Sponser";

export const ResourcesPage = () => {
  return (
    <div>
      <Resource />
      {/* <Sponser />
      <Footer /> */}
    </div>
  );
};
