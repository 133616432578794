import React from "react";
import { AiFillApple } from "react-icons/ai";
import { BsSpotify } from "react-icons/bs";
import { FaHeadphonesAlt } from "react-icons/fa";
import { FaPlayCircle, FaPlay } from "react-icons/fa";
import { AUDIO_PODCASTS_LINKS } from "../../constants";

export const Subscribe = () => {
  return (
    <section className="subscribe">
      <div className="container spacer-y">
        <div className="row justify-content-center text-center">
          <div className="col-lg-6">
            <p className="fw-600 heading-md text-center">
              Subscribe to the International Militaries’ Favorite Podcast.
            </p>
          </div>
          <div className="col-lg-10">
            <p className="para-1 muted-text">
              Join us as we explore deeply profound issues, fun things from the
              military, bad jokes, and amazing guests. We promote our veteran
              brethren all over the world. Welcome to the Smoke Pit.
            </p>
          </div>
        </div>
        <div className="row g-3 gx-lg-4 mt-30 mt-lg-5">
         
          <div className="col-lg-3 col-sm-6">
        <a href={AUDIO_PODCASTS_LINKS.ON_ITUNES}>
            <div className="subscribe-cards text-center">
              <div className="subs-icon">
                  <AiFillApple />
              </div>
              <div className="subs-name">On Itunes</div>
            </div>
         </a>
          </div>
          <div className="col-lg-3 col-sm-6">
                <a href={AUDIO_PODCASTS_LINKS.ON_SPOTIFY}>
            <div className="subscribe-cards text-center">
              <div className="subs-icon">
                  <BsSpotify />
              </div>
              <div className="subs-name">On Spotify</div>
            </div>
                </a>
          </div>
          <div className="col-lg-3 col-sm-6">
                <a href={AUDIO_PODCASTS_LINKS.ON_TUNEIN}>
            <div className="subscribe-cards text-center">
              <div className="subs-icon">
                  <FaHeadphonesAlt />
              </div>
              <div className="subs-name">On Tunein</div>
            </div>
                </a>
          </div>
          <div className="col-lg-3 col-sm-6">
                <a href={AUDIO_PODCASTS_LINKS.ON_GOOGLE}>
            <div className="subscribe-cards text-center">
              <div className="subs-icon">
                  <FaPlayCircle />
              </div>
              <div className="subs-name">On Google Playefe</div>
            </div>
                </a>
          </div>
        </div>
      </div>
    </section>
  );
};
