import React from "react";
import vfw from "../../images/vfw.png";
import nspl from "../../images/nspl.png";
import vc from "../../images/vc.png";
import $ from "jquery";
import "./Resources.css";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { AiOutlineArrowDown, AiOutlineArrowUp } from "react-icons/ai";

export const Resource = () => {
  const handleMore = () => {
    $(function () {
      $(".vfw-text").addClass("expanded");
    });
  };
  const handleLess = () => {
    $(".vfw-text").removeClass("expanded");
  };
  return (
    <div>
      <section className="resourses-intro">
        <div className="container spacer-y pb-0 text-center">
          <p className="heading-md fw-600">
            Resources- Outreach Programs & Help
          </p>
          <h3 className="mt-3 mb-2">Resources for help...</h3>
          <p className="para-1 muted-text">
            Here at Pop Smoke, we’re all about the lols [and have done many
            lunatic things to get them], but even we need to call in QRF when
            the mission gets tough and we need more guns in the fight. If
            talking to us doesnt help, reach out to these organisations for a
            quick assist when you need fires danger close.
          </p>
        </div>
      </section>
      <section className="vfw">
        <div className="container spacer-y pb-0">
          <LazyLoadImage src={vfw} className="w-100 mb-3" alt="vfw" />
          <div className="vfw-text">
            <h4 className="mb-3">Veterans Of Foriegn Wars</h4>
            <p className="muted-text mb-3">
              The Veterans of Foreign Wars of the United States is a nonprofit
              veterans service organisations comprised of eligible veterans and
              military service members from the active, guard and reserve
              forces.
            </p>
            <p className="muted-text -mb-3">
              We trace our roots back to 1899 when veterans of the
              Spanish-American War (1898) and the Philippine Insurrection
              (1899-1902) found local organisations to secure rights and
              benefits for their service.Many arrived home wounded or sick.
              There was no medical care or veterans pension for them, and they
              were left to care for themselves.
            </p>
            <p className="muted-text">
              In their misery, some of these veterans banded together and formed
              organizations that would eventually band together and become known
              as the Veterans Ohio, Colorado and Pennsylvania, the movement
              quickly gained momemtum.
            </p>
            <p className="read-more " onClick={handleMore}>
              <span className="theme-button">
                Read More <AiOutlineArrowDown />
              </span>
            </p>
            <p className="read-less " onClick={handleLess}>
              <span className="theme-button">
                Read Less <AiOutlineArrowUp />
              </span>
            </p>
          </div>
        </div>
      </section>
      <section className="nspl">
        <div className="container spacer-y pb-0">
          <LazyLoadImage src={nspl} className="w-100 mb-3" alt="nspl" />
          <h4 className="mb-3">
            National Suicide Prevention Lifeline 1-800-273-8255
          </h4>
          <p className="muted-text">
            We can all help prevent suicide. The Lifeline provides 24/7, free
            and confidential support for people in distress, prevention and
            crisis resources for you or your loved ones, and best practices for
            professionals.
          </p>
        </div>
      </section>
      <section className="nspl">
        <div className="container spacer-y pb-0">
          <LazyLoadImage src={vc} className="w-100 mb-3" alt="vc" />
          <h4 className="mb-3">Veterans Crisis Line</h4>
          <p className="muted-text">
            The Veterans Crisis Line connects Veterans in crisis and their
            families and friends with qualified, caring Department of Veterans
            Affairs responders through a confidential toll-free hotline, online
            chat, or text. Veterans and their loved ones can call 1-800-273-8255
            and Press 1,
            <a
              name="chat online"
              target="_blank"
              href="https://www.veteranscrisisline.net/ChatTermsOfService.aspx"
              className="text-decoration-underline mx-1"
            >
              Chat online
            </a>
            , or send a text message to 838255 to receive confidential support
            24 hours a day, 7 days a week, 365 days a year. Support for
            <a
              name="hard hearing"
              target="_blank"
              href="https://www.veteranscrisisline.net/GetHelp/Accessibility.aspx"
              className="text-decoration-underline mx-1"
            >
              deaf and hard of hearing
            </a>
            individuals is available.
          </p>
        </div>
      </section>
    </div>
  );
};
