import React from "react";
import { AdLong } from "../../Components/Ad-long/AdLong";
import { Footer } from "../../Components/Footer/Footer";
import { LatestVideos } from "../../Components/LatestVideosAbout/LatestVideos";
import { NewsLetter } from "../../Components/NewsLetter/NewsLetter";
import { Sponser } from "../../Components/Sponser/Sponser";
import { Subscribe } from "../../Components/Subscribe/Subscribe";
import { TheTeam } from "../../Components/Team/TheTeam";
import "./AboutPage.css";
import AboutNewsLetterForm from "./AboutNewsLetterForm";
import GoogleAds from "../../Components/GoogleAds/Ad-long";

export const AboutPage = () => {
  return (
    <>
      <AboutNewsLetterForm />
      <TheTeam />
      <GoogleAds />
      <Subscribe />
      <LatestVideos />
      {/* <Sponser />
      <Footer /> */}
    </>
  );
};
