import { useState, useEffect, React } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import {
  setRequestInProgress,
  setRequestSuccess,
  setRequestFailure,
  setRequestInit,
} from "../../redux/Slices/RequestFormSlice";
import { addContact } from "../../api/endpoints";
import { DEFAULT_VALUES } from "../../constants";

import "react-phone-input-2/lib/material.css";
import PhoneInput from "react-phone-input-2";

let formTypePayload = { type: "contact" };
function UserContactForm() {
  const dispatch = useDispatch();
  const { contact } = useSelector((state) => state.requestForm);
  const validationSchema = Yup.object().shape({
    firstName: Yup.string().trim().required("First name is required.").matches(/^[a-zA-Z\s]*$/, 'First name must contain only letters.'),
    lastName: Yup.string().trim().required("Last name is required.").matches(/^[a-zA-Z\s]*$/, 'Last name must contain only letters.'),
    phone: Yup.string()
      .required("Phone number is required.")
      .max(15, "Phone number can not be longar than 15 digits.")
      .min(8, "Phone number can not be less than 8 digits."),
    email: Yup.string()
      .email("Email is invalid.")
      .required("Email is required."),
    message: Yup.string().trim().required("Message is required."),
    department: Yup.string().required("Department is required."),
    privacyPolicyTerms: Yup.bool().oneOf([true], "This field is required."),
  });

  const [contactDetails, setContatDetails] = useState({
    phoneNo: "",
    isoCode: DEFAULT_VALUES.COUNTRY_ISO,
    dialCode: DEFAULT_VALUES.COUNTRY_DIAL_CODE,
  });

  const formOptions = { resolver: yupResolver(validationSchema) };
  const { register, handleSubmit, setValue, formState, reset } =
    useForm(formOptions);
  const { errors } = formState;

  const onContactNoChange = (value, country, e, formattedValue) => {
    const { dialCode: country_code, countryCode: country_iso_code } = country;
    const _phoneNo = value.slice(country_code.length);

    setContatDetails({
      ...contactDetails,
      phoneNo: _phoneNo,
      isoCode: country_iso_code,
      dialCode: country_code,
    });
    setValue("phone", _phoneNo);
  };
  // end of handling input changes

  const onSubmitForm = async (data) => {
    try {
      dispatch(setRequestInProgress(formTypePayload));
      const payloadData = {
        first_name: data.firstName,
        last_name: data.lastName,
        role: data.department,
        email: data.email,
        phone: contactDetails.phoneNo,
        country: `+${contactDetails.dialCode}`,
        message: data.message,
      };
      const {
        status,
        data: { msg },
      } = await addContact(payloadData);
      if (status === 200) {
        formTypePayload = { ...formTypePayload, successMessage: msg };
        dispatch(setRequestSuccess(formTypePayload));
        reset();
        setContatDetails({
          phoneNo: "",
          isoCode: DEFAULT_VALUES.COUNTRY_ISO,
          dialCode: DEFAULT_VALUES.COUNTRY_DIAL_CODE,
        });
        setTimeout(() => {
          dispatch(setRequestInit(formTypePayload));
        }, 3000);
      } else {
        formTypePayload = {
          ...formTypePayload,
          errorMessage:
            "Getting error while sending your request. Please try  again.",
        };
        dispatch(setRequestFailure(formTypePayload));
      }
    } catch (error) {
      const {
        response: {
          data: { msg },
        },
      } = error;
      formTypePayload = { ...formTypePayload, errorMessage: msg };
      dispatch(setRequestFailure(formTypePayload));
    }
  };

  return (
    <>
      <div className="row g-0 justify-content-center">
        <div className="spacer-left col-lg-10 ">
          <div className="spacer-y ">
            <p className="heading-md fw-600">Drop Us A Line…</p>
            <p className="muted-text">
              Have an idea you want to share, a story to tell, or just need to
              talk? Just drop us a line and we’ll get back to you as quickly as
              we can!
            </p>
            <form
              className="form-wrapper mt-30"
              onSubmit={handleSubmit(onSubmitForm)}
            >
              <div className="row">
                <div className="col-sm-6 mb-3">
                  <label for="first_name" className="contact-label form-label">
                    First Name
                  </label>
                  <input
                    readOnly={contact?.loader}
                    {...register("firstName")}
                    type="text"
                    name="firstName"
                    id="first_name"
                    placeholder="First name"
                    className="form-control"
                  />
                  {errors.firstName ? (
                    <span className="text-danger">
                      {errors.firstName.message}
                    </span>
                  ) : (
                    ""
                  )}
                </div>
                <div className="col-sm-6 mb-3">
                  <label for="first_name" className="contact-label form-label">
                    Last Name
                  </label>
                  <input
                    readOnly={contact?.loader}
                    {...register("lastName")}
                    type="text"
                    name="lastName"
                    id="last_name"
                    placeholder="Last name"
                    className="form-control"
                  />
                  {errors.lastName ? (
                    <span className="text-danger">
                      {errors.lastName.message}
                    </span>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6 mb-3">
                  <label for="email" className="contact-label form-label">
                    Email
                  </label>
                  <input
                    readOnly={contact?.loader}
                    {...register("email")}
                    type="email"
                    name="email"
                    id="email"
                    placeholder="info@popsmokemedia.com"
                    className="form-control"
                  />
                  {errors.email ? (
                    <span className="text-danger">{errors.email.message}</span>
                  ) : (
                    ""
                  )}
                </div>
                <div className="col-lg-6 mb-3">
                  <label for="last_name" className="contact-label form-label">
                    Phone Number
                  </label>
                  {/* <div className=""> */}
                  <PhoneInput
                    disabled={contact?.loader}
                    specialLabel={false}
                    country={contactDetails.isoCode}
                    value={`${contactDetails.dialCode}${contactDetails.phoneNo}`}
                    placeholder="Enter phone number"
                    countryCodeEditable={false}
                    enableSearch={true}
                    onChange={(value, country, e, formattedValue) =>
                      onContactNoChange(value, country, e, formattedValue)
                    }
                    // isValid={() => !!errors.phone ? 'Contact number*' : true}
                  />
                  <input
                    {...register("phone")}
                    type="hidden"
                    value={contactDetails.phoneNo}
                  />
                  {errors.phone ? (
                    <span className="text-danger">{errors.phone.message}</span>
                  ) : (
                    ""
                  )}

                  {/* <select
                                        {...register('countryCode')}
                                        disabled={contact?.loader}
                                        name="countryCode"
                                        id=""
                                        className="form-select form-control"
                                    >
                                        <option value="+1">US</option>
                                        <option value="+91">IN</option>
                                    </select>

                                    <input
                                        readOnly={contact?.loader}
                                        {...register('phone')}
                                        type="text"
                                        name="phone"
                                        id="phone"
                                        placeholder="760 - 598 - 2525"
                                        className="form-control"
                                {errors.phone ? <span className='text-danger'>{errors.phone.message}</span> : ""}
                                    /> */}
                  {/* </div> */}
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12 mb-3">
                  <label for="last_name" className="contact-label form-label">
                    {" "}
                    Select Department
                  </label>
                  <select
                    disabled={contact?.loader}
                    {...register("department")}
                    name="department"
                    id=""
                    className="form-select form-control"
                  >
                    <option value="Sales">Sales</option>
                    <option value="Support">Support </option>
                    <option value="Media Relations">Media Relations</option>
                  </select>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12 mb-3">
                  <label for="last_name" className="contact-label form-label">
                    Message
                  </label>
                  <textarea
                    readOnly={contact?.loader}
                    {...register("message")}
                    name="message"
                    id=""
                    cols="30"
                    rows="5"
                    className="form-control"
                    placeholder="Type Your Message"
                  ></textarea>
                  {errors.message ? (
                    <span className="text-danger">
                      {errors.message.message}
                    </span>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-lg-12">
                  <input
                    readOnly={contact?.loader}
                    {...register("privacyPolicyTerms")}
                    name="privacyPolicyTerms"
                    type="checkbox"
                    id="privacy-policy"
                    className="form-check-input me-2"
                  />
                  <label htmlFor="privacy-policy" className="form-label">
                    You agree to our friendly&nbsp;
                    <a
                      href="privacy-policy"
                      target="/"
                      className="text-decoration-underline"
                    >
                      privacy policy
                    </a>
                  </label>
                </div>
                {errors.privacyPolicyTerms ? (
                  <span className="text-danger">
                    {errors.privacyPolicyTerms.message}
                  </span>
                ) : (
                  ""
                )}
              </div>

              {Object.keys(errors).length === 0 && contact?.errorMessage ? (
                <p className="text-danger">{contact?.errorMessage}</p>
              ) : (
                ""
              )}
              {Object.keys(errors).length === 0 && contact?.successMessage ? (
                <p className="text-success">{contact?.successMessage}</p>
              ) : (
                ""
              )}

              <button
                disabled={contact?.loader}
                type="submit"
                className="theme-button contact-submit w-100 mt-3"
              >
                {contact?.loader ? "Please wait..." : "Send Message"}
              </button>
            </form>
          </div>
        </div>
        {/* <div className="col-lg-6">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d102316.20317244536!2d-76.36368659699373!3d36.737416536704295!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89baa57a802f6d99%3A4c7b1b1790710b79!2sChesapeake%2C%20VA%2C%20USA!5e0!3m2!1sen!2sin!4v1669200095110!5m2!1sen!2sin"
            className="contact-map"
            style={{ border: "0" }}
            allowFullScreen=""
            loading="lazy"
            title=" "
            referrerPolicy="no-referrer-when-downgrade"
          ></iframe>
        </div> */}
      </div>
    </>
  );
}
export default UserContactForm;
