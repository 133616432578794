// import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";
import React from "react";
import { ImArrowUpRight2 } from "react-icons/im";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Link } from "react-router-dom";
import { image, routeName } from "../../constants";

import { CircularProgress } from "@mui/material";
import { Box } from "@mui/system";
import { wordsCapping } from "../../utils/helpers";
function RelatedNews(props) {
  const { loader, news, newsBulletin } = props;
  const options = { year: "numeric", month: "long", day: "numeric" };

  if (loader) {
    return (
      <>
        <Box
          sx={{
            display: "flex",
            height: "60vh",
            alignItems: "center",
            justifyContent: "center",
            opacity: "0.8",
            zIndex: "1000",
          }}
        >
          <CircularProgress sx={{ color: "white" }} />
        </Box>
      </>
    );
  }

  if (news.length === 0) {
    return (
      <>
        <p className="m-auto mt-5 text-center">coming soons</p>
      </>
    );
  }

  return (
    <>
      {news?.map((news) => (
        <React.Fragment key={`intes-desc-${news?.id}-${news.post_name}`}>
          <div className="col-lg-3 col-sm-6">
            <div className="recent-news-post">
              <div className="more-news-image">
                <Link
                  to={`/${routeName(newsBulletin, news?.id, news.post_name)}`}
                >
                  <LazyLoadImage
                    src={news.image_high ? image(news) : "/placeholder-img.png"}
                    alt={`${news.title}`}
                    className={
                      news.image_high ? "" : "img-fluid placeholder-img w-50"
                    }
                  />
                </Link>
              </div>
              <div className="mt-30">
                <Link
                  to={`/${routeName(newsBulletin, news?.id, news.post_name)}`}
                >
                  <div className="recent-news-content">
                    <p className="para-4 muted-text topic-detail-wrapper mt-3">
                      <span className="topic-autor-name">
                        By {news?.user?.name}
                      </span>
                      <span className="topic-upload-date">
                        {new Date(news.published_date).toLocaleDateString(
                          "en-US",
                          options
                        )}
                      </span>

                      {news.sub_categories?.map((item) => (
                        <span key={item.id} className="topic-upload-cat">
                          {item.name}
                        </span>
                      ))}
                    </p>
                    <div className="d-flex justify-content-between my-2">
                      <p className="fw-600">{wordsCapping(news.title, 60)}</p>
                      <div className="news-arrow-icon">
                        <ImArrowUpRight2 />
                      </div>
                    </div>
                    <p className="muted-text">{wordsCapping(news.summary)}</p>
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </React.Fragment>
      ))}
    </>
  );
}

export default RelatedNews;
