import React from "react";
import { ContactForAd } from "../../Components/ContactAd/ContactForAd";
import { Footer } from "../../Components/Footer/Footer";
import { Header } from "../../Components/Header/Header";
import { Sponser } from "../../Components/Sponser/Sponser";
import { SponserDescription } from "../../Components/SponsersDesc/SponsersDesc";
import { TrendingNews } from "../../Components/Trending News/TrendingNews";


export const SponserPage = () => {
  return (
    <div>
      <SponserDescription />
      <ContactForAd />
      {/* <Sponser />
      <Footer /> */}
    </div>
  );
};
