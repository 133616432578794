import React, { useEffect, useState, useRef } from "react";
import sponser1 from "../../images/sponser-1.png";
import sponser2 from "../../images/sponser-2.png";
import sponser3 from "../../images/sponser-3.png";
import sponser4 from "../../images/sponser-4.png";

import { LazyLoadImage } from "react-lazy-load-image-component";
import { SPONSERS_LINKS, assets_images } from "../../constants";
import { getSponserList } from "../../api/endpoints";

// import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';

import SwiperCore, {Autoplay, Controller, Navigation, Pagination, Scrollbar, A11y  } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';


SwiperCore.use([Autoplay, Controller]);

export const Sponser = ({sponsors}) => {
  
  //for swipping and sliding
  const [controlledNewSwiper, setControlledSwiper] = useState(null);
  const controlledSwiper = useRef(null);

  useEffect(() => {
    if (controlledSwiper.current && controlledSwiper.current.controller) {
      // Update Swiper instance on window resize
      const handleResize = () => {
        controlledSwiper.current.controller.update();
      };

      window.addEventListener("resize", handleResize);

      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }
  }, []);


  // useEffect(() => {
  //   new Swiper(".sponser-slider", {
  //     slidesPerView: 2,
  //     spaceBetween: 10,
  //     loop: false,
  //     initialSlide: 0, // Add this line
  //     centeredSlides: true,
  //     breakpoints: {
  //       0: {
  //         slidesPerView: 2,
  //         spaceBetween: 20,
  //       },
  //       768: {
  //         slidesPerView: 3,
  //         spaceBetween: 40,
  //       },
  //       1024: {
  //         slidesPerView: 4,
  //         spaceBetween: 50,
  //         loop: false,
  //       },
  //     },
  //     autoplay: {
  //       delay: 5000,
  //     },
  //   });
  // }, []);
  



  if(sponsors?.length === 0){ 
    return <Layout>Coming soon</Layout>
  }

  return <>
    <Layout>
        <Swiper
            onSwiper={setControlledSwiper}
            modules={[Autoplay, Controller]}
            // modules={[Autoplay, Controller]}
            allowTouchMove={true}
            slidesPerView={2}
            spaceBetween={10}
            observer={true}
            observeParents={true}
            // navigation
            // pagination={{ clickable: true }}
            // scrollbar={{ draggable: true }}
            effect="slide"
            loop={true}
            // onSlideChange={() => console.log('==> slide change')}
            autoplay={{
              delay: 1000, // Delay between slides in milliseconds
              disableOnInteraction: false, // Autoplay continues after user interaction
            }}
      
            breakpoints={{
              320: {
                slidesPerView: 1,
                spaceBetween: 20,
              },
              480: {
                slidesPerView: 2,
                spaceBetween: 20,
              },
              766: {
                slidesPerView: 2,
                spaceBetween: 20,
              },
              768: {
                slidesPerView: 3,
                spaceBetween: 20,
              },
              1024: {
                slidesPerView: 4,
                spaceBetween: 30,
              },
            }}

          >
            <div
              className="swiper text-slider"
              style={{ border: "1px solid black" }}
            >
              <div className="swiper-wrapper">
              {sponsors?.map((item)=>(
                 <SwiperSlide key={item.id}>
                  <a href={item?.url} target="_blank">
                    <div className="swiper-slide">
                        <div className="text-slider sponors-image">
                        <LazyLoadImage
                          src={`${assets_images}/${item?.thumb_nail}`}
                          className="w-100"
                          alt="sponser"
                        />
                        </div>
                    </div>
                  </a>
                 </SwiperSlide>
              ))}
             
              </div>
          </div>
        </Swiper>
      {/* {sponsors?.map((item)=>(
         <div key={item.id} className="swiper-slide">
          <a href={`${item?.url}`}>
            <LazyLoadImage
              src={`${assets_images}/${item?.thumb_nail}`}
              className="w-100"
              alt="sponser"
            />
          </a>
       </div>
      ))} */}
    </Layout>
  </>
};

const Layout = ({ children }) =>{
  return (<section className="sponser">
      <div className="container spacer-y">
        <div className="section-header">
          <h3>Our Sponsors</h3>
          <hr />
        </div>
        <div className="swiper sponser-slider text-slider mt-30">
          <div className="swiper-wrapper sponser-content-div">
            {children}
          </div>
        </div>
      </div>
    </section>)
}
