export const wordsCapping = (_STRING, _LENGTH = 100) => {
  var TEXT = document.createElement("textarea");
  TEXT.innerHTML = _STRING;
  return `${TEXT.value.slice(0, _LENGTH)}${TEXT.value.length > _LENGTH ? `...` : ``}`;
};

export function matchYoutubeUrl(url) {
  var p =
    /^(?:https?:\/\/)?(?:www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
  var matches = url.match(p);
  if (matches) return true;
  return false;
}

export function matchAcastUrl(url) {
  const domainToMatch = "acast.com";
  const regex = new RegExp(domainToMatch.replace(".", "\\."), "i");
  return regex.test(url);
}

export function acastEmbededUrl(URL) {
  const separator = "/episodes/";
  const parts = URL.split(separator);
  const podcastId = parts[0].split("https://shows.acast.com/");
  return `https://embed.acast.com/$/${podcastId[1]}/?theme=custom&bgColor=424b5a&accentColor=F0F2F5`;
}

export function htmlDecode(content) {
  let e = document.createElement("div");
  let _content = content.replace("&lt;", "<");
  _content = _content.replace("&gt;", ">");
  e.innerHTML = content;
  return e.childNodes.length === 0 ? "" : e.childNodes[0].nodeValue;
}

//   export function decodeHTMLEntities(text) {
//     const element = document.createElement('div');
//     element.innerHTML = text;
//     return element.textContent;
//   }

export function encodeHTMLEntities(text) {
  const element = document.createElement("div");
  element.textContent = text;
  return element.innerHTML;
}

export function decodeHTMLEntities(text) {
  return (
    text
      .replace(/&lt;/g, "<")
      .replace(/&gt;/g, ">")
      .replace(/&amp;/g, "&")
      .replace(/&quot;/g, '"')
      .replace(/&apos;/g, "'")
      .replace(/&nbsp;/g, " ")
      // Add more replacements for other HTML entities as needed
      .replace(/&ldquo;/g, "“")
      .replace(/&rdquo;/g, "”")
  );
}

export function callBackCopyToClipboard(text) {
  var textArea = document.createElement("textarea");
  textArea.value = text;
  
  // Avoid scrolling to bottom
  textArea.style.top = "0";
  textArea.style.left = "0";
  textArea.style.position = "fixed";

  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  try {
    var successful = document.execCommand('copy');
    var msg = successful ? 'successful' : 'unsuccessful';
  } catch (err) {
    // console.error('Fallback: Oops, unable to copy', err);
  }

  document.body.removeChild(textArea);
}

export function getBulletinCategoryName( pathname ) {
  let response = pathname.split('category');
  return response[response.length-1].replace(/\//g, "");
}

export function AddMoreScript( _url ) {
  var scriptTag = document.createElement( 'script' );
  scriptTag.setAttribute( 'src', _url );
  document.body.appendChild( scriptTag );
}