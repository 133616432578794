import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    forms: {},
};

export const requestFormSlice = createSlice({
    name: "requestFrom",
    initialState,
    reducers: {
        setRequestInProgress: (state, action) => {
            const { type } = action.payload;
            return {
                ...state.forms,
                [type]: {
                    loader: true,
                    errorMessage: null,
                    successMessage: null,
                },
            };
        },

        setRequestSuccess: (state, action) => {
            const { type, successMessage } = action.payload;
            return {
                ...state.forms,
                [type]: {
                    loader: false,
                    errorMessage: null,
                    successMessage: successMessage,
                },
            };
        },

        setRequestFailure: (state, action) => {
            const { type, errorMessage } = action.payload;
            return {
                ...state.forms,
                [type]: {
                    loader: false,
                    errorMessage: errorMessage,
                    successMessage: null,
                },
            };
        },

        setRequestInit: (state, action) => {
            const { type } = action.payload;
            return {
                ...state.forms,
                [type]: {
                    loader: false,
                    errorMessage: null,
                    successMessage: null,
                },
            };
        },
    },
});

export const {
    setRequestInProgress,
    setRequestSuccess,
    setRequestFailure,
    setRequestInit,
} = requestFormSlice.actions;
export default requestFormSlice.reducer;
