/* eslint-disable jsx-a11y/no-distracting-elements */
import React, { useState, useEffect } from "react";
import "./TrendingNew.css";
import Marquee from "react-fast-marquee";
import trendAd from "../../../images/trend-ad.png";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { GOOGLE_ADDS_KEY, routeName } from "../../../constants";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Box, CircularProgress } from "@mui/material";
import { wordsCapping } from "../../../utils/helpers";
import { setTrendingNews } from "../../../redux/Slices/NewsSlice";
import { fetchTendingNews } from "../../../api/endpoints";
// import AdSense from 'react-adsense';
import {Adsense} from '@ctrl/react-adsense'

function TrendingNews() {
  const { trendingNews } = useSelector((state) => state.news);
  const [isLoading, setLoading] = useState(
    trendingNews.length === 0 ? true : false
  );
  const dispatch = useDispatch();

  async function getTrendingNews() {
    try {
      const {
        status,
        data: { data },
      } = await fetchTendingNews();

      if (status === 200) {
        dispatch(setTrendingNews(data));
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  }

  useEffect(() => {
    if (trendingNews.length === 0) {
      getTrendingNews();
    }
  }, []);

  if (isLoading) {
    return (
      <>
        <section className="trending-news">
          <div className="trend-news-wrapper container">
            <Box
              sx={{
                display: "flex",
                height: "40px",
                alignItems: "center",
                justifyContent: "center",
                opacity: "0.8",
                zIndex: "1000",
                margin: "0 auto",
              }}
            >
              <CircularProgress sx={{ color: "white" }} />
            </Box>
          </div>
        </section>
      </>
    );
  }

  return (
    <section className="trending-news">
      <div className="trend-news-wrapper container">
        <div className="trend-news">
          <Link to={'/category/trending-news'} className="tag">Trending News</Link>
          {trendingNews && trendingNews.length > 0 ? (
            <Marquee play={true} speed={30} loop={0} pauseOnHover>
                {trendingNews?.map((news, index) => (
                <React.Fragment key={`trendingnews-${news.post_name}`}>
                  <Link
                    className=" m-auto"
                    to={`/${routeName(
                      news.news_bulletin?.name,
                      news.id,
                      news.post_name
                    )}`}
                  >
                    {wordsCapping(news.title, 100)}
                  </Link>
                </React.Fragment>
                ))}
              </Marquee>
          ) : (
            <span className="m-auto">coming soon</span>
          )}
        </div>
        <div className="trend-advrt">
          <Adsense
            client={GOOGLE_ADDS_KEY.DATA_CLIENT}
            slot={GOOGLE_ADDS_KEY.DATA_SLOT}
            style={{ display: 'block', width:'450px', height:'50px' }}
            format="fluid"
          />
        </div>
      </div>
    </section>
  );
}

export default TrendingNews;
