import { createSlice, current } from '@reduxjs/toolkit';

const initialState = [];

export const headerFooterSlice = createSlice({
  name: "headerFooterData",
  initialState,
  reducers: {
    getWebsiteDataAction: (state, action) => {
      const actionPayload = action.payload
       return actionPayload
    },
  },
});

export const {
    getWebsiteDataAction
} = headerFooterSlice.actions;
export default headerFooterSlice.reducer;