import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { date, image, port, routeName } from "../../constants";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { setSearchData } from "../../redux/Slices/NewsSlice";
import { Box } from "@mui/system";
import { CircularProgress } from "@mui/material";
import { LazyLoadImage } from "react-lazy-load-image-component";
import Parser from "html-react-parser";
import { wordsCapping } from "../../utils/helpers";
import { searchNews } from "../../api/endpoints";

export const Search = () => {
  const navigate = useNavigate();
  const { title } = useParams();

  // const { searchData:searchNewsData } = useSelector((state) => state.news);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [isLoadMore, setLoadmore] = useState(true);
  const [searchData, setSearchData] = useState([]);
  const dispatch = useDispatch();
  // const [isLoading, setLoading] = useState(topStoriesNews.length === 0 ? true : false);
  if (!title) {
    navigate("/");
  }

  async function getSearchNews(page) {
    try {
      setLoading(true);
      const {
        status,
        data: {
          data,
          metData: { totalPages },
        },
      } = await searchNews(title, page);
      if (status === 200 && data.length > 0) {
        data.forEach((element, index) => {
          setSearchData((preData) => [...preData, element]);
        });
        setLoading(false);
        if (page === totalPages) {
          setLoadmore(false);
        }
      } else {
        setSearchData([]);
        setLoading(false);
      }
    } catch (error) {
      // further handling...
      setLoading(false);
    }
  }

  useEffect(() => {
    const section = document.querySelector(".container");
    section.scrollIntoView({ behavior: "smooth", block: "start" });
    setSearchData([]);

    getSearchNews(1);
  }, [title]);

  const loadMoreNews = () => {
    getSearchNews(page + 1);
    setPage(page + 1);
  };

  return (
    <div className="container" style={{ paddingTop: "60px" }}>
      <div className="topics-match">
        {!searchData?.length > 0 ? (
          loading ? (
            <Box
              onClick={() => setLoading(false)}
              sx={{
                display: "flex",
                height: "60vh",
                alignItems: "center",
                justifyContent: "center",
                opacity: "0.8",
                zIndex: "1000",
              }}
            >
              <CircularProgress sx={{ color: "white" }} />
            </Box>
          ) : (
            <div>
              <div
                style={{
                  fontSize: "22px",
                  fontWeight: "bold",
                  marginBottom: "10px",
                }}
              >
                No result found
              </div>
              <div className="muted-text mb-3">
                The page you requested could not be found. Try refining your
                search, or use the navigation above to locate the post.
              </div>
            </div>
          )
        ) : (
          <>
            <div className="section-header">
              <h3>Results</h3>
              <hr />
            </div>

            <div className="row g-3 mt-30">
              {searchData.map((news, index) => (
                <div
                  key={`${page}-${news.id}-${title}-${index}`}
                  className="col-lg-6 col-sm-6"
                >
                  <Link
                    to={`/${routeName(
                      news.news_bulletin?.name,
                      news.id,
                      news.post_name
                    )}`}
                    className="topic-cards"
                  >
                    <div className="topic-image mb-4">
                      {/* <LazyLoadImage src={image(news)} alt={`${s.title}`} /> */}
                      <img
                        src={
                          news.image_high ? image(news) : "/placeholder-img.png"
                        }
                        className={
                          news.image_high
                            ? ""
                            : "img-fluid placeholder-img w-50"
                        }
                        alt={`${news.title}`}
                      />
                    </div>
                    <h5>{wordsCapping(news?.title, 80)}</h5>
                    <p className="para-3 muted-text my-2 dsds">
                      {wordsCapping(news.summary, 120)}
                    </p>
                    <p className="para-4 muted-text topic-detail-wrapper">
                      <span className="topic-autor-name">
                        By {news?.user?.name}
                      </span>
                      <span className="topic-upload-date">{date(news)}</span>
                    </p>
                  </Link>
                </div>
              ))}
            </div>
            {isLoadMore && (
              <div className="col-md-2 m-auto">
                <button
                  disabled={loading}
                  className="form-control theme-button mt-4"
                  onClick={() => loadMoreNews()}
                >
                  {loading ? `Loading...` : `Load more`}
                </button>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};
