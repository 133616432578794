/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AdLong } from "../../Components/Ad-long/AdLong";
import { Box } from "@mui/system";
import { CircularProgress } from "@mui/material";
import "./IntelDesc.css";
import { BsFacebook, BsInstagram, BsTwitter, BsCalendarCheck } from "react-icons/bs";
import { FiCopy } from "react-icons/fi";

import { Link, useParams } from "react-router-dom";
import { APP_SETTINGS, DOMAIN_URL, TWEET_EMBEDED_SCRIPT_URL, date, image, video } from "../../constants";
import { useSelector } from "react-redux";
import ReactPlayer from "react-player/lazy";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { AddMoreScript, callBackCopyToClipboard } from "../../utils/helpers";
import {
  fetchNewsByIdAndSlug,
  fetchLatestNewsByBulletinId,
  fetchNewsBySlug
} from "../../api/endpoints";
import RelatedNews from "./RelatedNews";
import { decodeHTMLEntities } from "../../utils/helpers";
import PageNotFound from "../PageNotFound";
import GoogleAds from "../../Components/GoogleAds/Ad-long";

export const IntelDesc = () => {
  const navigate = useNavigate();
  const [loading, setloading] = useState(true);
  const [relatedNewloading, setRelatedNewloading] = useState(true);
  const [data, setdata] = useState({});
  const [relatedNews, setRelatedNews] = useState([]);
  const { newsBulletin } = useSelector((state) => state.news);
  const [copyLinkText, setCopyLinkText] = useState("Copy link");
  const [is404, set404] = useState(false);

  const copyLink = async () => {
    try {

      if (!navigator.clipboard) {
        callBackCopyToClipboard(`${DOMAIN_URL}${window.location.pathname}`);
        setCopyLinkText("Copied");
        return;
      }
      
      await window.navigator.clipboard.writeText(
        `${DOMAIN_URL}${window.location.pathname}`
      );
      setCopyLinkText("Copied");
    } catch (err) {}
  };

  const getData = async () => {
    setloading(true);
    try {
      const { status, data: { data } } = await fetchNewsBySlug(slug);
      if (status === 200) {
        setdata(data);
        AddMoreScript(TWEET_EMBEDED_SCRIPT_URL);
        try {
          const { id, news_bulletin: { id: bulletinId } } = data;
          const {
            status: relatedNewsStatus,
            data: { data: relatedNewsData },
          } = await fetchLatestNewsByBulletinId(bulletinId, id);
          if (relatedNewsStatus === 200) {
            setRelatedNews(relatedNewsData);
          } else {
            console.warn("getting error on while getting related news");
          }
          setRelatedNewloading(false);
        } catch (error) {
          console.warn("getting error on while getting related news");
        }
      } else if (status === 204) {
        set404(true);
      } else {
        console.warn("news not found");
      }
      setloading(false);
    } catch (error) {
      if( error?.response?.status === 404 ){
        set404(true);
      }
      setloading(false);
    }
  };

  const {slug } = useParams();

  useEffect(() => {
    getData();
  }, [slug]);

  function createMarkup() {
    return { __html: data?.description };
  }

  if( is404 ){
    return <><PageNotFound /></>
  }

  return (
    <div>
      {loading ? (
        <Box
          onClick={() => setloading(false)}
          sx={{
            display: "flex",
            height: "60vh",
            alignItems: "center",
            justifyContent: "center",
            opacity: "0.8",
            zIndex: "1000",
          }}
        >
          <CircularProgress sx={{ color: "white" }} />
        </Box>
      ) : (
        <>
          {Object.keys(data).length === 0 ? (
            <div className="container mt-4">
              <div
                style={{
                  fontSize: "22px",
                  fontWeight: "bold",
                  marginBottom: "10px",
                }}
              >
                No result found
              </div>
              <div className="muted-text mb-3">
                The page you requested could not be found. Try refining your
                search, or use the navigation above to locate the post.
              </div>
            </div>
          ) : (
            <>
              <section className="intel-intro">
                <div className="container-small spacer-y">
                  <div
                    className="tag-lg "
                    style={{ marginBottom: "15px", marginRight: "10px" }}
                  >
                    {data?.news_bulletin?.name}
                  </div>
                  <div className="row justify-content-start text-left">
                    <div className="col-lg-12 mb-3">
                      <h1 className="heading-md fw-600">
                        {data?.title?.replace("&#39;", "'")}
                      </h1>
                    </div>
                    <div className="col-lg-12 mb-3">
                      <p className="para-1 muted-text topic-detail-wrapper">
                        <span className="topic-autor-name">
                          By {data?.user?.name}
                        </span>
                        <span className="topic-upload-date"><BsCalendarCheck className="mx-2" />{date(data)}</span>
                      </p>
                   
                    </div>
                  </div>
                  <p className="muted-text ">{data?.summary}</p>
                  {data.image_high && (
                    <LazyLoadImage
                      src={image(data)}
                      className="img-fluid mt-30 cover-img mx-auto d-block"
                      alt={`${data?.title}`}
                    />
                  )}
                </div>
              </section>
              <div className="container">
                {data.news_video_link && (
                  <ReactPlayer
                    url={video(data)}
                    // width="100%"
                    // height="100%"
                    className="video-desc"
                    controls
                    autoPlay
                    name="media"
                  ></ReactPlayer>
                )}
              </div>
              <div className="container-small spacer-y pt-0">
                <div
                  className="intel-text"
                  dangerouslySetInnerHTML={{
                    __html: decodeHTMLEntities(data?.description),
                  }}
                />
              </div>
              <GoogleAds />
              <section className="intel-desc-bottom">
                <div className="container spacer-y pb-0">
                  <hr />
                  <div className="badge-share">
                    <div className="wrapper-bagde">
                      <ul className="bagde list-unstyled">
                        {newsBulletin.slice(0, 2).map((e) => (
                          <li>
                            <Link
                              to={`/category/${e?.name.replace(/ /g, "").toLowerCase()}`}
                            >
                              {e.name}
                            </Link>
                          </li>
                        ))}
                      </ul>
                    </div>
                    <div className="wrapper-copy">
                      <div
                        onClick={() => copyLink()}
                        className="c-pointer copy-link"
                      >
                        <FiCopy />
                        {copyLinkText}
                      </div>
                      <ul className="about-socials list-unstyled">
                        <li>
                          <a
                            name="twitter"
                            href={`${APP_SETTINGS.TWITTER_SHARE}${window.location.pathname}`}
                            target="/"
                          >
                            <BsTwitter />
                          </a>
                        </li>
                        <li>
                          <a
                            name="twitter"
                            href={`${APP_SETTINGS.FB_SHARE}${window.location.pathname}`}
                            target="/"
                          >
                            <BsFacebook />
                          </a>
                        </li>
                        <li>
                          <a
                            name="instagram"
                            href={`${APP_SETTINGS.INSTA_SHARE}${window.location.pathname}`}
                            rel="noopener"
                            target="/"
                          >
                            <BsInstagram />
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </section>
            </>
          )}
        </>
      )}
      {!loading ? (
        <div className="container mt-4">
          <div className="row g-3">
            <RelatedNews
              loader={relatedNewloading}
              news={relatedNews}
              newsBulletin={data?.news_bulletin.name}
            />
          </div>
        </div>
      ) : (
        <Box
          sx={{
            display: "flex",
            height: "60vh",
            alignItems: "center",
            justifyContent: "center",
            opacity: "0.8",
            zIndex: "1000",
          }}
        >
          <CircularProgress sx={{ color: "white" }} />
        </Box>
      )}
    </div>
  );
};
