import React from "react";
import bigAd from "../../images/ad-big-width.png";
import { LazyLoadImage } from "react-lazy-load-image-component";
import {Adsense} from '@ctrl/react-adsense'
import { GOOGLE_ADDS_KEY } from "../../constants";

export const AdLong = () => {
  return (
    <>
      <section className="ad-long">
        <div className="container spacer-y py-3 ">
          <div className="advrt-wide">
            <Adsense
              client={GOOGLE_ADDS_KEY.DATA_CLIENT}
              slot={GOOGLE_ADDS_KEY.DATA_SLOT}
              style={{ display: 'block', width:'55px', height:'355px' }}
              format="fluid"
              layout="in-article"
            />
          </div>
        </div>
      </section>
    </>
  );
};
