import React, { useEffect, useState } from "react";
import "./WorldNews.css";
import advtSquareqaure from "../../../images/advt-square.png";
import { getWorldNewsList } from "../../../api/endpoints";
import { GOOGLE_ADDS_KEY, date, image, routeName } from "../../../constants";
import { Link } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useDispatch, useSelector } from "react-redux";
import { Box, CircularProgress } from "@mui/material";
import { setWorldNews } from "../../../redux/Slices/NewsSlice";
import WorldNewsLetterForm from "./NewsLetterForm";
import { wordsCapping } from "../../../utils/helpers";
import { BsCalendarCheck } from "react-icons/bs";
import {Adsense} from '@ctrl/react-adsense'


function WorldNews() {
  const dispatch = useDispatch();
  const {
    newsBulletin,
    worldNews: { worldNews: wnewData, recentNews },
  } = useSelector((state) => state.news);
  const recent =
    recentNews !== undefined && recentNews.length > 0
      ? recentNews[recentNews.length - 1]
      : null;
  const [isLoading, setLoading] = useState(
    Object.keys(WorldNews).length === 0 ? true : false
  );

  useEffect(() => {
    if (Object.keys(WorldNews).length === 0) {
      getWorldNews();
      setLoading(false);
    }
  }, []);

  async function getWorldNews() {
    try {
      const {
        status,
        data: { data: worldNews },
      } = await getWorldNewsList();

      if (status === 200) {
        dispatch(setWorldNews(worldNews));
      } else {
        // further checks...
      }
    } catch (error) {
      // further handling...
    }
  }
  if (isLoading) {
    return (
      <>
        <Box
          sx={{
            display: "flex",
            height: "60vh",
            alignItems: "center",
            justifyContent: "center",
            opacity: "0.8",
            zIndex: "1000",
          }}
        >
          <CircularProgress sx={{ color: "white" }} />
        </Box>
      </>
    );
  }

  return (
    <section className="world-news">
      <div className="container spacer-y">
        <div className="section-header">
          <h3>World News</h3>
          <hr />
        </div>
        <div className="row g-3 mt-30">
          <div className="col-lg-8">
            <div className="world-news-wrapper mb-40">
              <div className="world-text">
                {wnewData ? (
                  <>
                    <Link
                      to={`/${routeName(
                        wnewData?.news_bulletin?.name,
                        wnewData?.id,
                        wnewData.post_name
                      )}`}
                      className="text-white"
                    >
                      <h3>{wordsCapping(wnewData.title)}</h3>
                      <div className="light-text mt-3">
                        {wordsCapping(wnewData.summary, 280)}
                      </div>
                    </Link>
                    <Link
                      to={`/${routeName(
                        wnewData?.news_bulletin?.name,
                        wnewData?.id,
                        wnewData.post_name
                      )}`}
                      className="secondary-button mt-3"
                    >
                      Read Now
                    </Link>
                  </>
                ) : (
                  <h3>coming soon</h3>
                )}
              </div>
              <div className="world-image">
                {wnewData ? (
                  <Link
                    to={`/${routeName(
                      wnewData?.news_bulletin?.name,
                      wnewData?.id,
                      wnewData.post_name
                    )}`}
                  >
                    <LazyLoadImage
                      src={
                        wnewData.image_high
                          ? image(wnewData)
                          : "/placeholder-img.png"
                      }
                      alt={`${wnewData?.title}`}
                    />
                  </Link>
                ) : (
                  <LazyLoadImage
                    src="/placeholder-img.png"
                    alt="placeholder-img.png"
                  />
                )}
              </div>
            </div>
            <div className="topics-match">
              <div className="section-header">
                <h3>Topic Match for You</h3>
                <hr />
              </div>
              <ul className="filter-list list-unstyled">
                {newsBulletin?.slice(0, 5)?.map((newsBulletin, index) => (
                  <li key={newsBulletin.id}>
                    <Link
                      to={`category/${newsBulletin?.slug
                        .replace(/ /g, "")
                        .toLowerCase()}`}
                      className={`filter-button ${index === 0 && "active"}`}
                    >
                      {newsBulletin?.name}
                    </Link>
                  </li>
                ))}
              </ul>
              <div className="row g-3 mt-30">
                {recentNews !== undefined && recentNews.length !== 0 ? (
                  recentNews.map((news, index) =>
                    index < recentNews.length - 1 ? (
                      <div key={news.id} className="col-lg-6 col-sm-6">
                        <Link
                          to={`/${routeName(
                            news.news_bulletin?.name,
                            news.id,
                            news.post_name
                          )}`}
                          className="topic-cards"
                        >
                          <div className="topic-image mb-4">
                            <div className="tag-lg">
                              {news?.news_bulletin?.name}
                            </div>
                            <LazyLoadImage
                              src={
                                news.image_high
                                  ? image(news)
                                  : "/placeholder-img.png"
                              }
                              className={
                                news.image_high
                                  ? ""
                                  : "img-fluid placeholder-img w-50"
                              }
                              alt={`${news.title}`}
                            />
                          </div>
                          <div style={{ fontWeight: "500" }}>
                            {wordsCapping(news.title, 70)}
                          </div>
                          <p className="para-3 muted-text my-2">
                            {wordsCapping(news.summary, 95)}
                          </p>
                          <p className="para-4 muted-text topic-detail-wrapper">
                            <span className="topic-autor-name">
                              By {news?.user?.name}
                            </span>
                            <span className="topic-upload-date">
                              <BsCalendarCheck className="" /> {date(news)}
                            </span>
                          </p>
                        </Link>
                      </div>
                    ) : (
                      ""
                    )
                  )
                ) : (
                  <div className="col-lg-6 col-sm-6">
                    <div className="topic-cards">
                      <div className="topic-image mb-4">
                        <LazyLoadImage
                          src="/placeholder-img.png"
                          alt=""
                          style={{ height: "400px" }}
                        />
                      </div>
                      <div style={{ fontWeight: "500" }}>coming soon</div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="side-advt abc">
              <Adsense
                client={GOOGLE_ADDS_KEY.DATA_CLIENT}
                slot={GOOGLE_ADDS_KEY.DATA_SLOT}
                style={{ display: 'block', width:'350px', height:'250px' }}
                format="fluid"
              />
            </div>
            <WorldNewsLetterForm />
            <hr className="mt-30" />
            {recent ? (
              <div className="topic-cards mt-30">
                <Link
                  to={`/${routeName(
                    recent?.news_bulletin?.name,
                    recent?.id,
                    recent.post_name
                  )}`}
                >
                  <div className="topic-image mb-4">
                    <LazyLoadImage
                      src={
                        recent.image_high
                          ? image(recent)
                          : "/placeholder-img.png"
                      }
                      className={
                        recent.image_high
                          ? ""
                          : "img-fluid placeholder-img w-50"
                      }
                      alt={`${recent?.title}`}
                    />
                  </div>
                  <div style={{ fontWeight: "500" }}>
                    {wordsCapping(recent.title, 70)}
                  </div>
                  <p className="para-3 muted-text my-2">
                    {wordsCapping(recent.summary, 100)}
                  </p>
                </Link>
                <Link
                  to={`/${routeName(
                    recent?.news_bulletin?.name,
                    recent?.id,
                    recent.post_name
                  )}`}
                  className="theme-button w-100 mt-3"
                >
                  Continue
                </Link>
              </div>
            ) : (
              <div className="topic-cards mt-30">
                <div className="topic-image mb-4">
                  <LazyLoadImage
                    src="/placeholder-img.png"
                    alt="placeholder-img.png"
                    style={{ height: "100%" }}
                  />
                </div>
              </div>
            )}

            <div className="more-topics mt-30">
              <h5>More Interesting Topic</h5>
              <ul className="more-topic-list list-unstyled mt-3">
                {newsBulletin?.slice(3, 12).map(
                  (newsBulletin) =>
                    !newsBulletin.parent_id && (
                      <li key={newsBulletin.id}>
                        <Link
                          to={`category/${newsBulletin?.slug
                            .replace(/ /g, "")
                            .toLowerCase()}`}
                          className="filter-button active"
                        >
                          {newsBulletin?.name}
                        </Link>
                      </li>
                    )
                )}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default WorldNews;
