import React, { useMemo, useState } from "react";
import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";
import { ImArrowUpRight2 } from "react-icons/im";
import { Link } from "react-router-dom";
import "./MoreNews.css";
import { image, routeName } from "../../constants";
import { useEffect } from "react";
import { getNewsByNamePage } from "../../api/endpoints";
import { LazyLoadImage } from "react-lazy-load-image-component";
import Card from "./Card";

export const MoreNews = ({ bulletin }) => {
  const [page, setpage] = useState(1);
  const [data, setdata] = useState([]);
  const newsType = window.location.pathname;

  useEffect(() => {
    getMoreNews();
  }, [page]);

  const getMoreNews = async () => {
    const data = bulletin?.find(
      (e) => e?.name?.toLowerCase().replace(/ /g, "") == newsType.slice(1)
    );
    try {
      const res = await getNewsByNamePage(data.id);
      setdata(res.data.data);
    } catch (error) {}
  };
  return (
    <section className="more-news">
      <div className="container spacer-y">
        <h3>More News</h3>
        <div className="row gx-3 gy-4 mt-30 mb-40">
          <Card data={data} />
        </div>
      </div>
    </section>
  );
};
