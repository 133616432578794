import { useEffect } from "react";
import { VALIDATION_RULES, VALIDATION_MESSAGE } from "../../constants";

import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useSelector, useDispatch } from "react-redux";
import { setRequestInProgress, setRequestSuccess, setRequestFailure, setRequestInit } from "../../redux/Slices/RequestFormSlice";
import { subscribe } from "../../api/endpoints";

let formTypePayload = {type:'contactNewsLetter'};

const validationSchema = yup.object().shape({
    email: yup.string()
      .required(VALIDATION_MESSAGE.EMAIL.REQUIRED)
      .email(VALIDATION_MESSAGE.EMAIL.INVALID),
  });

  
function ConatctNewsLetterForm() {
    const {contactNewsLetter} = useSelector(state => state.requestForm);
    const dispatch = useDispatch();

    const { register, formState: { errors }, handleSubmit, reset, } = useForm({
        mode: 'all',
        resolver: yupResolver(validationSchema),
      });
    
    const handleFormSubmit = async (formData) => {
        try {
            dispatch(setRequestInProgress(formTypePayload));
            const { status, data: { msg } } = await subscribe(formData.email);
            if (status === 200) {
                formTypePayload = {...formTypePayload, successMessage:msg};
                dispatch(setRequestSuccess(formTypePayload));
                reset();
                setTimeout(() => {
                    dispatch(setRequestInit(formTypePayload));
                  }, 3000);
            } else {
                formTypePayload = {...formTypePayload, errorMessage:"Unable to send your request. Please try  again."} 
                dispatch(setRequestFailure(formTypePayload));
                setTimeout(() => {
                    dispatch(setRequestInit({ type: "contactNewsLetter" }));
                  }, 3000);
            }
        } catch (error) {
            const { response: { data: { msg } } } = error
            formTypePayload = {...formTypePayload, errorMessage:msg} 
            dispatch(setRequestFailure(formTypePayload))
            setTimeout(() => {
                dispatch(setRequestInit({ type: "contactNewsLetter" }));
              }, 3000);
        }
    }
    
    useEffect(()=>{
        dispatch(setRequestInit(formTypePayload));
    },[])
    

    return <>
        <section className="newsletter border-top-0">
            <div className="container spacer-y">
                <div className="row">
                    <div className="col-lg-7">
                        <p className="fw-600 mb-2">Contact us</p>
                        <h2 className="mb-3">We’d Love To Hear From You </h2>
                        <p className="para-1 mt-2 muted-text mb-40">
                            If you need to reach out, feel free. We love hearing from our
                            readers and reading their input on how we can be better.
                        </p>
                    </div>
                    <div className="col-lg-6 col-md-8">
                        <form className="newsletter-form mt-30" onSubmit={handleSubmit(handleFormSubmit)} noValidate >
                            <input
                                {...register("email")}
                                readOnly={contactNewsLetter?.loader}
                                type="email"
                                name="email"
                                id=""
                                className="form-control newsletter-input"
                                placeholder="Email Address"
                            />

                            <button disabled={contactNewsLetter?.loader}
                                type="submit"
                                className="theme-button"
                            >
                                {contactNewsLetter?.loader ? "Please wait..." : "Subscribe"}
                            </button>
                        </form>
                        <div className="text-left mt-2">
                            {errors.email && (
                                <span style={{ color: "#CA2132", fontSize: "14px" }}>
                                     {errors.email.message}
                                </span>
                            )}
                            {Object.keys(errors).length === 0 && contactNewsLetter?.errorMessage ? <span className="text-danger">{contactNewsLetter?.errorMessage}</span> : ""}
                            {Object.keys(errors).length === 0 && contactNewsLetter?.successMessage ? <span className="text-success">{contactNewsLetter?.successMessage}</span> : ""}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </>
}

export default ConatctNewsLetterForm