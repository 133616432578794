import { configureStore } from "@reduxjs/toolkit";

import newsReducer from "./Slices/NewsSlice";
import requestSlice from "./Slices/RequestSlice";
import contactFormSlice from "./Slices/ContactFormSlice";
import requestFormSlice from "./Slices/RequestFormSlice";
import headerFooterSlice from "./Slices/HeaderFooterSlice";

const store = configureStore({
  reducer: {
    news: newsReducer,
    serviceRequest: requestSlice,
    contactForm: contactFormSlice,
    requestForm: requestFormSlice,
    headerFooterData: headerFooterSlice
  },
});

export default store;
