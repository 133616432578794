import { useEffect, useState } from "react";
import { getBannerPagenNews } from "../../api/endpoints";
import GoogleAds from "../../Components/GoogleAds/Ad-long";
import BreakingNews from "../../Components/HomePage/BreakingNews";
import TopStories from "../../Components/HomePage/TopStories";
import WorldNews from "../../Components/HomePage/WorldNews";
import TrendingNews from "../../Components/HomePage/TrendingNews";

import KeepInTouch from "../../Components/HomePage/KeepInTouch";

import { useDispatch, useSelector } from "react-redux";
import { setSearchData, setHomePageNews } from "../../redux/Slices/NewsSlice";
import { Search } from "../../Components/Search/Search";
import { Box, CircularProgress } from "@mui/material";

function Newhomepage() {
    const dispatch = useDispatch();
    const { homePageNews } = useSelector((state) => state.news);
    const [isLoading, setLoading] = useState(Object.keys(homePageNews).length === 0 ? true : false);

    useEffect(() => {
        dispatch(setSearchData([]));
        if (Object.keys(homePageNews).length === 0) {
            getNews();
        }
    }, []);

    async function getNews() {
        try {
            const { status, data: { data } } = await getBannerPagenNews();
            if (status === 200) {
                dispatch(setHomePageNews(data));
                setLoading(false);
            } else {
                setLoading(false);
            }
        } catch (error) {
            setLoading(false);
        }
    }

    if (isLoading) {
        return <>
            <Box sx={{
                display: "flex",
                height: "60vh",
                alignItems: "center",
                justifyContent: "center",
                opacity: "0.8",
                zIndex: "1000",
            }}>
                <CircularProgress sx={{ color: "white" }} />
            </Box>
        </>
    }

    return <>
        <div>
            <TrendingNews />
            <BreakingNews />
            <GoogleAds />
            <TopStories />
            <GoogleAds />
            <WorldNews />
            <KeepInTouch />
        </div>
    </>
}

export default Newhomepage;