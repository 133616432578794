import React from "react";
import bigAd from "../../../images/ad-big-width.png";
import {Adsense} from '@ctrl/react-adsense'
import { GOOGLE_ADDS_KEY } from "../../../constants";

function GoogleAds() {
  return (
    <>
      <section className="ad-long xyz">
        <div className="container spacer-y py-3 ">
          <div className="advrt-wide">
              <Adsense
                client={GOOGLE_ADDS_KEY.DATA_CLIENT}
                slot={GOOGLE_ADDS_KEY.DATA_SLOT}
                style={{ display: "block", height: '80px', width:'auto' }}
                format="fluid"
              />
          </div>
        </div>
      </section>
    </>
  );
}

export default GoogleAds;
