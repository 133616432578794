import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";
import { ImArrowUpRight2 } from "react-icons/im";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Link } from "react-router-dom";
import { img, image, routeName } from "../../../constants";
import ReactPaginate from "react-paginate";

import { wordsCapping } from "../../../utils/helpers";
import { CircularProgress } from "@mui/material";
import { Box } from "@mui/system";
import { BsCalendarCheck } from "react-icons/bs";

function MoreNews(props) {
  const { news, page, currentPage, totalPages, loading } = props;
  var options = { year: "numeric", month: "long", day: "numeric" };

  const handleClick = (event) => {
    const section = document.querySelector("#section-maindivelement");
    section.scrollIntoView({ behavior: "smooth", block: "start" });
    page(event.selected + 1);
  };
  return (
    <>
      <section id="section-maindivelement" className="more-news">
        <div className="container spacer-y">
          <h3>More News</h3>
          <div className="row gx-3 gy-4 mt-30 mb-40">
            {loading ? (
              <Box
                sx={{
                  display: "flex",
                  height: "60vh",
                  alignItems: "center",
                  justifyContent: "center",
                  opacity: "0.8",
                  zIndex: "1000",
                }}
              >
                <CircularProgress sx={{ color: "white" }} />
              </Box>
            ) : news.length > 0 ? (
              news?.map((e, i) => (
                <div className="col-lg-4 col-sm-6" key={e.id}>
                  <div className="recent-news-post">
                    <div className="more-news-image">
                      <Link
                        to={`/${routeName(
                          e?.news_bulletin?.name,
                          e?.id,
                          e.post_name
                        )}`}
                      >
                        <LazyLoadImage
                          alt={`${e.title}`}
                          src={`${
                            e?.image_high
                              ? `${img}${e?.image_high}`
                              : "/placeholder-img.png"
                          }`}
                          className={`${
                            e?.image_high
                              ? ""
                              : "img-fluid placeholder-img w-50"
                          }`}
                        />
                      </Link>
                    </div>
                    <div className="mt-30">
                      <Link
                        to={`/${routeName(
                          e?.news_bulletin?.name,
                          e?.id,
                          e.post_name
                        )}`}
                      >
                        <div className="recent-news-content">
                          <p className="para-4 muted-text topic-detail-wrapper mt-3">
                            <span className="topic-autor-name">
                              By {e?.user?.name}
                            </span>
                            <span className="topic-upload-date">
                            <BsCalendarCheck className="mr-1" />
                              {new Date(e.published_date).toLocaleDateString(
                                "en-US",
                                options
                              )}
                            </span>

                            {news.sub_categories?.map((item) => (
                              <span key={item.id} className="topic-upload-cat">
                                {item.name}
                              </span>
                            ))}
                          </p>
                          <div className="d-flex justify-content-between my-2">
                            <p className="fw-600">
                              {wordsCapping(e.title, 60)}
                            </p>
                            <div className="news-arrow-icon">
                              <ImArrowUpRight2 />
                            </div>
                          </div>
                          <p className="muted-text">
                            {wordsCapping(e.summary, 120)}
                          </p>
                        </div>
                      </Link>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <div
                style={{
                  height: "30vh",
                  position: "relative",
                  display: "flex",
                  right: "50px",
                  width: "100%",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <span>coming soon</span>
              </div>
            )}
          </div>
        </div>

        {news.length > 0 ? (
          <div className="col-md-12">
            <ReactPaginate
              breakLabel="..."
              nextLabel={
                currentPage + 1 < totalPages ? (
                  <div className="page-next">
                    <span>
                      Next
                      <AiOutlineArrowRight />
                    </span>
                  </div>
                ) : (
                  ""
                )
              }
              initialPage={currentPage}
              onPageChange={handleClick}
              disableInitialCallback={true}
              pageRangeDisplayed={3}
              pageCount={totalPages}
              previousLabel={
                currentPage + 1 > 1 ? (
                  <div className="page-prev">
                    <div>
                      <AiOutlineArrowLeft />
                      Previous
                    </div>
                  </div>
                ) : (
                  ""
                )
              }
              renderOnZeroPageCount={null}
              containerClassName="container pagination-wrapper pt-5  number-list list-unstyled"
            />
          </div>
        ) : (
          ""
        )}
      </section>
    </>
  );
}

export default MoreNews;
