import React from "react";
import { BsFacebook, BsInstagram, BsLinkedin, BsTwitter } from "react-icons/bs";
import daniel from "../../images/daniel.png";
import justin from "../../images/justin.png";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { USERS } from "../../constants";

export const TheTeam = () => {
  return (
    <section className="team">
      <div className="container spacer-y">
        <div className="section-header">
          <h3>The Team</h3>
          <hr />
        </div>
        <div className="row g-3 gx-lg-5 mt-30 mb-40">
          <div className="col-lg-6 order-2 order-md-1">
            <div className="about-text">
              <p className="heading-md fw-600 mb-3">DANIEL SHARP</p>
              <p className="para-1 muted-text mb-3">
                Daniel is a former Infantry Marine with 5 deployments in his 12
                years on active duty. He also is the creator of Pop Smoke Media,
                as well as the host of the internationally ranked Smoke Pit
                Podcast.
              </p>
              <p className="para-1 muted-text mb-3">
                Daniel began writing in 2017, and has published several hundred
                articles for various news outlets. These experiences have
                spanned from on-location reporting in Russia, to helping draft
                legislation at the Capitol.
              </p>
              <p className="para-1 muted-text mb-3">
                The Florida native created Pop Smoke Media with the vision of
                entertaining, educating, and advocating for the military
                community.
              </p>
              <p className="para-1 muted-text">
                “We would risk our lives for each other in combat, so we must be
                willing to fight for each other back home.”
              </p>
              <ul className="about-socials list-unstyled mt-30">
                <li>
                  <a name="twitter" href={USERS.DANIEL_SHARP.TWITTER}>
                    <BsTwitter />
                  </a>
                </li>
                <li>
                  <a name="facebook" href={USERS.DANIEL_SHARP.FB}>
                    <BsFacebook />
                  </a>
                </li>
                <li>
                  <a name="instagram" href={USERS.DANIEL_SHARP.INSTA}>
                    <BsInstagram />
                  </a>
                </li>
                <li>
                  <a name="linkedin" href={USERS.DANIEL_SHARP.LINKEDIN}>
                    <BsLinkedin />
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-lg-6 order-1 order-md-2">
            <div className="about-image">
              <LazyLoadImage src={daniel} className="img-fluid" alt="Daniel" />
            </div>
          </div>
        </div>
        <div className="row g-3 gx-lg-5 mt-30">
          <div className="col-lg-6">
            <div className="about-image">
              <LazyLoadImage src={justin} className="img-fluid" alt="Justin" />
            </div>
          </div>
          <div className="col-lg-6">
            <div className="about-text">
              <p className="heading-md fw-600 mb-3 text-uppercase">
                Justin Eggen
              </p>
              <p className="para-1 muted-text mb-4">
                Justin Eggen is a Father, 2x National Award-Winning poet,
                motivational speaker, OEF combat Veteran, and current graduate
                student at Florida Atlantic University. He also holds a Bachelor
                of Arts in political science from Florida Atlantic University,
                and is the President of Stand To: Change The Narrative Inc., a
                literacy-focused nonprofit organization that is challenging the
                common narrative while clearing a new path for positive and
                educational community growth.
              </p>
              <p className="para-1 muted-text mb-4">
                Justin Eggen served active-duty Marine Corps from 2008-2012 as a
                combat engineer attached to 2nd Combat Engineer Battalion, 2nd
                Marine Division serving in Marjah 2010 & Sangin 2011.
              </p>
              <ul className="about-socials list-unstyled mt-30">
                <li>
                  <a name="facebook" href={USERS.JUSTIN_EGGEN.FB}>
                    <BsFacebook />
                  </a>
                </li>
                <li>
                  <a name="instagram" href={USERS.JUSTIN_EGGEN.INSTA}>
                    <BsInstagram />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
