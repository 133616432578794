function PrivacyPolicy(props){
    return <>
        <section className="newsletter border-top-0">
            <div className="container spacer-y">
                <div className="row">
                    <div className="cpl-md-12">
                        <div className="content-sec">
                            <div>
                                <h1 className="mb-3 h2">Privacy Policy</h1>
                                <p>
                                Protecting your private information is our priority. This Statement of Privacy applies to www.popsmokemedia.com, and Pop Smoke Media and governs data collection and usage. For the purposes of this Privacy Policy, unless otherwise noted, all references to Pop Smoke Media include www.popsmokemedia.com, Pop Smoke Media, The Smoke Pit Podcast and other entities. The Pop Smoke Media website is a Media, & Entertainment site. By using the Pop Smoke Media website, you consent to the data practices described in this statement.
                                </p>
                            </div>
                            <div>
                                <h3>Collection of your Personal Information</h3>
                                <p>In order to better provide you with products and services offered, Pop Smoke Media may collect personally identifiable information, such as your:</p>

                                <ul>
                                    <li><span>First and Last Name</span></li>
                                    <li><span>E-mail Address</span></li>
                                </ul>
                                <p>Pop Smoke Media may also collect anonymous demographic information, which is not unique to you, such as your:</p>
                                <ul>
                                    <li><span>Age</span></li>
                                    <li><span>Gender</span></li>
                                </ul>
                            </div>
                            <div>
                                <p>Please keep in mind that if you directly disclose personally identifiable information or personally sensitive data through Pop Smoke Media’s public message boards, this information may be collected and used by others.</p>
                                <p>We do not collect any personal information about you unless you voluntarily provide it to us. However, you may be required to provide certain personal information to us when you elect to use certain products or services. These may include: (a) registering for an account; (b) entering a sweepstakes or contest sponsored by us or one of our partners; (c) signing up for special offers from selected third parties; (d) sending us an email message; (e) submitting your credit card or other payment information when ordering and purchasing products and services. To wit, we will use your information for, but not limited to, communicating with you in relation to services and/or products you have requested from us. We also may gather additional personal or non-personal information in the future.</p>
                            </div>
                            <div>
                                <h3>Use of your Personal Information</h3>
                                <p>Pop Smoke Media collects and uses your personal information to operate and deliver the services you have requested.</p>

                                <p>Pop Smoke Media may also use your personally identifiable information to inform you of other products or services available from Pop Smoke Media and its affiliates.</p>
                            </div>
                            <div>
                                <h3>Sharing Information with Third Parties</h3>
                                <p>Pop Smoke Media does not sell, rent or lease its customer lists to third parties.</p>

                                <p>Pop Smoke Media may share data with trusted partners to help perform statistical analysis, send you email or postal mail, provide customer support, or arrange for deliveries. All such third parties are prohibited from using your personal information except to provide these services to Pop Smoke Media, and they are required to maintain the confidentiality of your information.</p>

                                <p>Pop Smoke Media may disclose your personal information, without notice, if required to do so by law or in the good faith belief that such action is necessary to: (a) conform to the edicts of the law or comply with legal process served on Pop Smoke Media or the site; (b) protect and defend the rights or property of Pop Smoke Media; and/or (c) act under exigent circumstances to protect the personal safety of users of Pop Smoke Media, or the public.</p>
                            </div>
                            <div>
                                <h3>Tracking User Behavior</h3>
                                <p>Pop Smoke Media may keep track of the websites and pages our users visit within Pop Smoke Media, in order to determine what Pop Smoke Media services are the most popular. This data is used to deliver customized content and advertising within Pop Smoke Media to customers whose behavior indicates that they are interested in a particular subject area.</p>
                            </div>
                            <div>
                                <h3>Automatically Collected Information</h3>
                                <p>Information about your computer hardware and software may be automatically collected by Pop Smoke Media. This information can include: your IP address, browser type, domain names, access times and referring website addresses. This information is used for the operation of the service, to maintain quality of the service, and to provide general statistics regarding use of the Pop Smoke Media website.</p>
                            </div>
                            <div>
                                <h3>Use of Cookies</h3>
                                <p>The Pop Smoke Media website may use “cookies” to help you personalize your online experience. A cookie is a text file that is placed on your hard disk by a web page server. Cookies cannot be used to run programs or deliver viruses to your computer. Cookies are uniquely assigned to you, and can only be read by a web server in the domain that issued the cookie to you.</p>
                                <p>One of the primary purposes of cookies is to provide a convenience feature to save you time. The purpose of a cookie is to tell the Web server that you have returned to a specific page. For example, if you personalize Pop Smoke Media pages, or register with Pop Smoke Media site or services, a cookie helps Pop Smoke Media to recall your specific information on subsequent visits. This simplifies the process of recording your personal information, such as billing addresses, shipping addresses, and so on. When you return to the same Pop Smoke Media website, the information you previously provided can be retrieved, so you can easily use the Pop Smoke Media features that you customized.</p>
                                <p>
                                You have the ability to accept or decline cookies. Most Web browsers automatically accept cookies, but you can usually modify your browser setting to decline cookies if you prefer. If you choose to decline cookies, you may not be able to fully experience the interactive features of the Pop Smoke Media services or websites you visit.
                                </p>
                            </div>
                            <div>
                                <h3>Links</h3>
                                <p>This website contains links to other sites. Please be aware that we are not responsible for the content or privacy practices of such other sites. We encourage our users to be aware when they leave our site and to read the privacy statements of any other site that collects personally identifiable information.</p>
                            </div>
                            <div>
                                <h3>Security of your Personal Information</h3>
                                <p>Pop Smoke Media secures your personal information from unauthorized access, use, or disclosure. Pop Smoke Media may use the following methods for this purpose:</p>
                                <ul>
                                    <li><span>SSL Protocol</span></li>
                                </ul>
                                <p>When personal information (such as a credit card number) is transmitted to other websites, it is protected through the use of encryption, such as the Secure Sockets Layer (SSL) protocol.</p>
                                <p>We strive to take appropriate security measures to protect against unauthorized access to or alteration of your personal information. Unfortunately, no data transmission over the Internet or any wireless network can be guaranteed to be 100% secure. As a result, while we strive to protect your personal information, you acknowledge that: (a) there are security and privacy limitations inherent to the Internet which are beyond our control; and (b) security, integrity, and privacy of any and all information and data exchanged between you and us through this Site cannot be guaranteed.</p>
                            </div>
                            <div>
                                <h3>Right to Deletion</h3>
                                <p>Subject to certain exceptions set out below, on receipt of a verifiable request from you, we will:</p>
                                <ul>
                                    <li><span>Delete your personal information from our records; and</span></li>
                                    <li><span>Direct any service providers to delete your personal information from their records.</span></li>
                                </ul>
                                <p>Please note that we may not be able to comply with requests to delete your personal information if it is necessary to:</p>
                                <ul>
                                    <li><span>Complete the transaction for which the personal information was collected, fulfill the terms of a written warranty or product recall conducted in accordance with federal law, provide a good or service requested by you, or reasonably anticipated within the context of our ongoing business relationship with you, or otherwise perform a contract between you and us;</span></li>
                                    <li><span>Detect security incidents, protect against malicious, deceptive, fraudulent, or illegal activity; or prosecute those responsible for that activity</span></li>
                                    <li><span>Debug to identify and repair errors that impair existing intended functionality;</span></li>
                                    <li><span>Exercise free speech, ensure the right of another consumer to exercise his or her right of free speech, or exercise another right provided for by law.</span></li>
                                    <li><span>Comply with the California Electronic Communications Privacy Act;</span></li>
                                    <li><span>Engage in public or peer-reviewed scientific, historical, or statistical research in the public interest that adheres to all other applicable ethics and privacy laws, when our deletion of the information is likely to render impossible or seriously impair the achievement of such research, provided we have obtained your informed consent;</span></li>
                                    <li><span>Enable solely internal uses that are reasonably aligned with your expectations based on your relationship with us;</span></li>
                                    <li><span>Comply with an existing legal obligation; or</span></li>
                                    <li><span>Otherwise use your personal information, internally, in a lawful manner that is compatible with the context in which you provided the information.</span></li>
                                </ul>
                            </div>
                            <div className="row mt-2">
                                <h3>Children Under Eighteen</h3>
                                <p>Pop Smoke Media does not knowingly collect personally identifiable information from children under the age of eighteen. If you are under the age of eighteen, you must ask your parent or guardian for permission to use this website.</p>
                            </div>
                            <div>
                                <h3>Disconnecting your Pop Smoke Media Account from Third Party Websites</h3>
                                <p>You will be able to connect your Pop Smoke Media account to third party accounts. BY CONNECTING YOUR POP SMOKE MEDIA ACCOUNT TO YOUR THIRD PARTY ACCOUNT, YOU ACKNOWLEDGE AND AGREE THAT YOU ARE CONSENTING TO THE CONTINUOUS RELEASE OF INFORMATION ABOUT YOU TO OTHERS (IN ACCORDANCE WITH YOUR PRIVACY SETTINGS ON THOSE THIRD PARTY SITES). IF YOU DO NOT WANT INFORMATION ABOUT YOU, INCLUDING PERSONALLY IDENTIFYING INFORMATION, TO BE SHARED IN THIS MANNER, DO NOT USE THIS FEATURE. You may disconnect your account from a third party account at any time. Users may learn how to disconnect their accounts from third-party websites by visiting their “My Account” page on the respective platform.</p>
                            </div>
                            <div>
                                <h3>E-mail Communications</h3>
                                <p>From time to time, Pop Smoke Media may contact you via email for the purpose of providing announcements, promotional offers, alerts, confirmations, surveys, and/or other general communication.</p>
                                <p>If you would like to stop receiving marketing or promotional communications via email from Pop Smoke Media, you may opt out of such communications by Customers may unsubscribe from emails by “replying STOP” or “clicking on the UNSUBSCRIBE button.”.</p>
                            </div>
                            <div>
                                <h3>Changes to this Statement</h3>
                                <p>Pop Smoke Media reserves the right to change this Privacy Policy from time to time. We will notify you about significant changes in the way we treat personal information by sending a notice to the primary email address specified in your account, by placing a prominent notice on our website, and/or by updating any privacy information. Your continued use of the website and/or Services available after such modifications will constitute your: (a) acknowledgment of the modified Privacy Policy; and (b) agreement to abide and be bound by that Policy.</p>
                            </div>
                            <div>
                                <h3>Contact Information</h3>
                                <p>Pop Smoke Media welcomes your questions or comments regarding this Statement of Privacy. If you believe that Pop Smoke Media has not adhered to this Statement, please contact Pop Smoke Media at:</p>
                                <div className="mt-3">
                                    <p>Pop Smoke Media</p>
                                    <p>PO Box 434</p>
                                    <p>Fredericksburg, Virginia 22404</p>
                                </div>
                                <div className="mt-3">
                                    <p>Email Address:</p>
                                    <p>info@popsmokemedia.com</p>
                                </div>
                                <div className="mt-3">
                                    <p>Telephone number:</p>
                                    <p><a href="telto:(540) 547-8487">(540) 547-8487</a></p>
                                </div>
                            </div>
                            <div>
                               <p>Effective as of February 01, 2015</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </section> 
    </>
}
export default PrivacyPolicy;