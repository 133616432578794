import React, { useState } from "react";
import "./ContactForm.css";
import { addContact } from "../../api/endpoints";
import "react-toastify/dist/ReactToastify.css";
import UserContactForm from "./UserContactForm";

export const ContactForm = () => {
  return (
    <div>
      <section className="contact-form">
        <div className="container-fluid p-0">
          <UserContactForm />
        </div>
      </section>
    </div>
  );
};
