import { useEffect } from "react";

import { VALIDATION_RULES, VALIDATION_MESSAGE } from "../../constants";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import { useSelector, useDispatch } from "react-redux";
import {
  setRequestInProgress,
  setRequestSuccess,
  setRequestFailure,
  setRequestInit,
} from "../../redux/Slices/RequestSlice";
import { subscribe } from "../../api/endpoints";

const validationSchema = yup.object().shape({
  email: yup
    .string()
    .required(VALIDATION_MESSAGE.EMAIL.REQUIRED)
    .email(VALIDATION_MESSAGE.EMAIL.INVALID),
});
function NewsLetterForm() {
  const { loader, errorMessage, successMessage } = useSelector(
    (state) => state.serviceRequest
  );
  const dispatch = useDispatch();

  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm({
    mode: "all",
    resolver: yupResolver(validationSchema),
  });

  if (Object.keys(errors).length > 0) {
    dispatch(setRequestInit());
  }

  const handleFormSubmit = async (formData) => {
    try {
      dispatch(setRequestInProgress());
      const {
        status,
        data: { msg },
      } = await subscribe(formData.email);
      if (status === 200) {
        dispatch(setRequestSuccess(msg));
        reset();
        setTimeout(() => {
          dispatch(setRequestInit());
        }, 3000);
      } else {
        dispatch(
          setRequestFailure("Unable to send your request. Please try  again.")
        );
        setTimeout(() => {
          dispatch(setRequestInit());
        }, 3000);
      }
    } catch (error) {
      const {
        response: {
          data: { msg },
        },
      } = error;
      dispatch(setRequestFailure(msg));
      setTimeout(() => {
        dispatch(setRequestInit());
      }, 3000);
    }
  };

  useEffect(() => {
    dispatch(setRequestInit());
  }, []);

  return (
    <>
      <section className="newsletter">
        <div className="container spacer-y">
          <div className="small-width m-auto text-center">
            <h2>Keep In Touch </h2>
            <p className="para-1 mt-2">
              Subscribe to learn about new product features, the latest in
              technology, solutions, and updates.
            </p>
            <form
              className="newsletter-form mt-30"
              onSubmit={handleSubmit(handleFormSubmit)}
              noValidate
            >
              <input
                {...register("email")}
                readOnly={loader}
                type="email"
                name="email"
                id=""
                className="form-control newsletter-input"
                placeholder="Email Address"
              />

              <button disabled={loader} type="submit" className="theme-button">
                {loader ? "Please wait..." : "Subscribe"}
              </button>
            </form>
            <div className="text-start mt-2">
              {errors.email && (
                <span style={{ color: "#ca2132", fontSize: "14px" }}>
                  {errors.email.message}
                </span>
              )}
              {errorMessage ? (
                <span className="text-danger">{errorMessage}</span>
              ) : (
                ""
              )}
              {successMessage ? (
                <span className="text-success">{successMessage}</span>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default NewsLetterForm;
