import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  news: [],
  newsBulletin: [],
  searchData: [],
  homePageNews: {},
  topStoriesNews: [],
  trendingNews: [],
  randomNews: [],
  worldNews: {},
  sponsors: [],
};

export const newsSlice = createSlice({
  name: "news",
  initialState,
  reducers: {
    setNews: (state, action) => {
      state.news = action.payload;
    },
    setNewsBulletin: (state, action) => {
      state.newsBulletin = action.payload;
    },
    setSearchData: (state, action) => {
      state.searchData = action.payload;
    },
    setHomePageNews: (state, action) => {
      state.homePageNews = action.payload;
    },
    setTopStoriesNews: (state, action) => {
      state.topStoriesNews = action.payload;
    },
    setTrendingNews: (state, action) => {
      state.trendingNews = action.payload;
    },
    setRandomNews: (state, action) => {
      state.randomNews = action.payload;
    },
    setWorldNews: (state, action) => {
      state.worldNews = action.payload;
    },
    setSponsors: (state, action) => {
      state.sponsors = action.payload;
    },
  },
});

export const {
  setNews,
  setNewsBulletin,
  setSearchData,
  setHomePageNews,
  setTopStoriesNews,
  setTrendingNews,
  setRandomNews,
  setWorldNews,
  setSponsors,
} = newsSlice.actions;
export default newsSlice.reducer;
