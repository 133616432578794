import React, { useEffect, useRef, useState } from "react";
import "./Header.css";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { format } from "date-fns";
import { GrTwitter } from "react-icons/gr";
import { FaFacebookF } from "react-icons/fa";
import { BsSearch, BsYoutube } from "react-icons/bs";
import $ from "jquery";
import { Link, NavLink, useNavigate, useParams } from "react-router-dom";
import { AiFillInstagram } from "react-icons/ai";
import { useLocation } from "react-router-dom";
import {
  VALIDATION_RULES,
  VALIDATION_MESSAGE,
  assets_images,
} from "../../constants";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useSelector, useDispatch } from "react-redux";
import { setNewsBulletin } from "../../redux/Slices/NewsSlice";
import { getHeaderFooterData } from "../../api/endpoints";

const validationSchema = yup.object().shape({
  search: yup.string().required(VALIDATION_MESSAGE.REQUIRED.MESSAGE),
});

export const Header = ({ data }) => {
  //opening search box
  // const {pathname} = useLocation();
  const pathName = window.location.pathname;

  const siteData = useSelector((state)=> state.headerFooterData)
  const handleSearch = () => {
    $(".search-form").toggleClass("active");
    $(".hamburger").removeClass("active");
    $(".menu").removeClass("active");
    window.setTimeout(function () { 
      document.getElementById('search-result-input').focus(); 
    }, 400); 
  };

  const handleSearchClose = () => {
    $(".search-form").removeClass("active");
  };

  const { news } = useSelector((state) => state.news);

  const {
    register,
    trigger,
    setValue,
    clearErrors,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm({
    mode: "all",
    resolver: yupResolver(validationSchema),
    defaultValues: {
      // search:''
    },
  });

  const handleSearchFormFormSubmit = async (data) => {
    try {
      navigate(`/search/${encodeURIComponent(data.search)}`);
      reset();
      setSerachFormSubmitted(true);
    } catch (error) {}
  };

  //scroll to top
  const topRef = useRef(null);
  const scrollToUp = () =>
    topRef.current.scrollIntoView({ behaviour: "smooth" });

  useEffect(() => {
    scrollToUp();
    handleSearchClose();
    const hamm = $(".hamburger");
    hamm.removeClass("active");
    $(".menu").removeClass("active");
  }, [pathName]);


 

  // navbar li
  const navList = [
    { name: "Newsroom", pathname: "/" },
    { name: "Intel", pathname: "/category/intel" },
    { name: "Industry", pathname: "/category/industry" },
    { name: "Featured", pathname: "/category/featured" },
    { name: "Podcast", pathname: "/category/podcast" },
    { name: "Contact Us", pathname: "/contact-us" },
  ];

  const [searchTitle, setsearchTitle] = useState("");
  const [serachFormSubmitted, setSerachFormSubmitted] = useState(false);
  //for hamburger toggle
  const handleOnclick = () => {
    const hamm = $(".hamburger");
    hamm.toggleClass("active");
    $(".menu").toggleClass("active");
    $(".search-form").removeClass("active");
  };

  const navigate = useNavigate();

  const handleSearchSubmit = (e) => {
    e.preventDefault();
    navigate(`/search/${searchTitle}`);
    setsearchTitle("");
    handleSearch();
  };

  //for latest date
  let date = format(new Date(), "eeee, MMMM d, yyyy");

  useEffect(() => {
    const keyDownHandler = (event) => {
      if (event.key === "Enter") {
        event.preventDefault();
        if (event.target.value === "") {
          return trigger("search");
        }
        if (event.target.name === "search") {
          setSerachFormSubmitted(false);
        }
        if (event.target.value !== "" && event.target.name === "search") {
          handleSubmit(
            handleSearchFormFormSubmit({ search: event.target.value })
          );
        }
        // reset();
      } else {
        // setValue('search', searchTitle)
        // trigger("search");
      }
    };

    document.addEventListener("keydown", keyDownHandler);

    return () => {
      document.removeEventListener("keydown", keyDownHandler);
    };
  }, []);

 

  return (
    <>
      <section className="top-nav-wrapper" ref={topRef}>
        <div className="top-nav container">
          <div className="top-left">
            {/* <Link to="/">
              <LazyLoadImage
                // src="/assets/images/popsmoke-mask-logo.png"
                src={`${assets_images}/${allData?.HEADER_CONTENT?.value?.logo?.image_high}`}
                style={{ height: "40px", marginRight: "5px" }}
                alt="popsmoke logo"
              />
            </Link> */}
            <p>
              {siteData?.HEADER_CONTENT?.value?.text?.main &&
                siteData?.HEADER_CONTENT?.value?.text?.main}
            </p>
          </div>
          <div className="top-right">
            <p className="day today">{date}</p>
            <ul className="top-socials list-unstyled">
              <li>
                <a
                  href={
                    siteData?.SOCIAL_LINKS?.value?.facebook?.url &&
                    `${siteData?.SOCIAL_LINKS?.value?.facebook?.url}`
                  }
                  target="_blank"
                >
                  <FaFacebookF />
                </a>
              </li>
              <li>
                <a
                  name="twitter"
                  href={
                    siteData?.SOCIAL_LINKS?.value?.twitter?.url &&
                    `${siteData?.SOCIAL_LINKS?.value?.twitter?.url}`
                  }
                  target="_blank"
                >
                  <GrTwitter />
                </a>
              </li>
              <li>
                <a
                  name="instagram"
                  href={
                    siteData?.SOCIAL_LINKS?.value?.instagram?.url &&
                    `${siteData?.SOCIAL_LINKS?.value?.instagram?.url}`
                  }
                  target="_blank"
                >
                  <AiFillInstagram />
                </a>
              </li>
              <li>
                <a
                  href={
                    siteData?.SOCIAL_LINKS?.value?.youtube?.url &&
                    `${siteData?.SOCIAL_LINKS?.value?.youtube?.url}`
                  }
                  target="_blank"
                >
                  <BsYoutube />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </section>

      <header className="header">
        <div className="navigation container">
          <div className="item-left">
            <Link to="/">
              <LazyLoadImage
                src={`${assets_images}/${siteData?.HEADER_CONTENT?.value?.logo?.image_high}`}
                className="logo"
                alt="logo"
              />
            </Link>
          </div>
          <div className="item-right">
            <ul className="menu list-unstyled">
              {navList.map((nav, i) => (
                <li className="menu-list" key={i} id="menu-id">
                  <NavLink className=" menu-list" to={nav.pathname}>
                    {nav.name}
                  </NavLink>
                </li>
              ))}
            </ul>
            <div className="hamburger" onClick={handleOnclick}>
              <span> </span>
              <span> </span>
              <span> </span>
            </div>

            <BsSearch className="search-toggler" onClick={handleSearch} />
            <div className="search-form">
              <form
                id="search-form"
                onSubmit={handleSubmit(handleSearchFormFormSubmit)}
                className="search-input"
                noValidate
              >
                <input
                  {...register("search")}
                  type="search"
                  name="search"
                  placeholder="Search news"
                  // value={searchTitle}
                  // onChange={(e) => setsearchTitle(e.target.value)}
                  id="search-result-input"
                  className="form-control theme-form search-area"
                  autoFocus
                />

                <button type="submit" className="search-button m-0">
                  <BsSearch />
                </button>
                {errors.search && serachFormSubmitted === false && (
                  <span style={{ color: "#CA2132", fontSize: "14px" }}>
                    {errors.search.message}
                  </span>
                )}
              </form>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};
