import React, { useEffect, useState } from "react";

import "./Intel.css";
import TrendingNews from "../../Components/HomePage/TrendingNews";
import RecentNews from "../../Components/BulletinsNews/RecentNews";
import GoogleAds from "../../Components/GoogleAds/Ad-long";
import NewsLetterForm from "../../Components/BulletinsNews/NewsLetterForm";
import MoreNews from "../../Components/BulletinsNews/MoreNews";
import { fetchNewsByBulletin } from "../../api/endpoints";
import { useLocation } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import { Box } from "@mui/system";
import { getBulletinCategoryName } from "../../utils/helpers";

function BulletinsNews () {
  
  const [isLoading, setLoading] = useState(true);
  const [moreNewsLoader, setMoreNewsLoader] = useState(false);
  const [bulletinNews, setBulletinNews] = useState([]);
  const [pageNo, setPageNo] = useState(1)
  const [totalPages, setTotalPages] = useState(1)
  const [recentNews, setRecentNews] =  useState([]);
  const {pathname} = useLocation();

  // const bulletin = window.location.pathname.replace(/\//g, "");
  const bulletin = getBulletinCategoryName(pathname);
  async function getBulletinNews(){
    try{
      const {status, data:{data, totalPages}} = await fetchNewsByBulletin(bulletin);
      if( status === 200 ){
        setRecentNews(data.slice(0, 3));
        setBulletinNews(data.slice(3, data.length));
        setTotalPages(totalPages)
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (error){
      setLoading(false);
    }
  }

  useEffect(() => {
    getBulletinNews(bulletin);
    setLoading(true);
    setPageNo(1)
  }, [bulletin]);

  useEffect(() => {
    async function getMoreBulletinNews(){
      setMoreNewsLoader(true)
      try{
        const {status, data:{data, totalPages}} = await fetchNewsByBulletin(bulletin, pageNo);
        if( status === 200 ){
          setBulletinNews( pageNo === 1 ? data.slice(3, data.length) : data);
          setTotalPages(totalPages)
          setMoreNewsLoader(false);
        } else {
          setMoreNewsLoader(false);
        }
      } catch (error){
        setMoreNewsLoader(false);
      }
    }
    getMoreBulletinNews();
  }, [pageNo]);

  if( isLoading ){
    return <>
      <Box sx={{
          display: "flex",
          height: "60vh",
          alignItems: "center",
          justifyContent: "center",
          opacity: "0.8",
          zIndex: "1000",
      }}>
        <CircularProgress sx={{ color: "white" }} />
      </Box>
    </>
  }

  return <>
      <TrendingNews />
      <main>
        <GoogleAds />
        <RecentNews bulletin={bulletin} news={recentNews} />
        <NewsLetterForm />
        <MoreNews 
            loading={moreNewsLoader}
            news={bulletinNews} 
            page={setPageNo}
            currentPage={pageNo-1}
            totalPages={totalPages}
        />
      </main>
    </>
};

export default BulletinsNews;