import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "./BreakingNews.css";
import "swiper/css/navigation";
import "swiper/css/controller";
import { Controller, Navigation } from "swiper";
import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";
import { useSelector } from "react-redux";
import { date, image, routeName } from "../../../constants";
import { Link } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { wordsCapping } from "../../../utils/helpers";
import { BsCalendarCheck } from "react-icons/bs";

function BreakingNews() {
  // navigation buttons
  const navigationPrevRef = React.useRef(null);
  const navigationNextRef = React.useRef(null);
  const {
    homePageNews: { card: frontPageNews, newsList },
  } = useSelector((state) => state.news);
  // state for controlling swipe of text slider using image slider
  const [controlledSwiper, setControlledSwiper] = useState(null);

  return (
    <>
      <section className="breaking-news">
        <div className="container spacer-y">
          <div className="row g-3">
            <div className="col-lg-8">
              <div className="news-slider position-relative">
                <div className="text-slider-wrapper white-bg">
                  <Swiper
                    onSwiper={setControlledSwiper}
                    modules={[Controller]}
                    allowTouchMove={false}
                    spaceBetween={40}
                    effect="slide"
                    loop={true}
                  >
                    <div
                      className="swiper text-slider"
                      style={{ border: "1px solid black" }}
                    >
                      <div className="swiper-wrapper">
                        {frontPageNews?.length > 0 ? (
                          frontPageNews.map((news) => (
                            <SwiperSlide key={news.id}>
                              <div className="swiper-slide">
                                <div className="text-slider">
                                  <Link
                                    to={`/${routeName(
                                      news.news_bulletin?.name,
                                      news.id,
                                      news.post_name
                                    )}`}
                                  >
                                    <div className="tag-sm mb-2">
                                      {news.news_bulletin?.name}
                                    </div>
                                    <h3
                                      className="mb-2"
                                      style={{ wordWrap: "break-word" }}
                                    >
                                      {wordsCapping(news.title, 80)}
                                    </h3>
                                    <div
                                      className="muted-text mb-2"
                                      style={{ wordWrap: "break-word" }}
                                    >
                                      {wordsCapping(news.summary)}
                                    </div>
                                  </Link>
                                  <Link
                                    to={`/${routeName(
                                      news.news_bulletin?.name,
                                      news.id,
                                      news.post_name
                                    )}`}
                                  >
                                    Read More
                                  </Link>
                                  <div className="date"><span> <BsCalendarCheck /> {date(news)}</span>
                                  </div>
                                </div>
                              </div>
                            </SwiperSlide>
                          ))
                        ) : (
                          <SwiperSlide>
                            <div className="swiper-slide">
                              <div className="text-slider">
                                <h3
                                  className="mb-2"
                                  style={{ wordWrap: "break-word" }}
                                >
                                  Coming soon.
                                </h3>
                              </div>
                            </div>
                          </SwiperSlide>
                        )}
                      </div>
                    </div>
                    <div className="slider-buttons">
                      <div className="news-prev" ref={navigationPrevRef}>
                        <AiOutlineArrowLeft />
                      </div>
                      <div className="news-next" ref={navigationNextRef}>
                        <AiOutlineArrowRight />
                      </div>
                    </div>
                  </Swiper>
                </div>
                <div className="image-slider-wrapper">
                  <div className="swiper image-slider">
                    <Swiper
                      controller={{ control: controlledSwiper }}
                      speed={1000}
                      spaceBetween={10}
                      grabCursor={true}
                      modules={[Navigation, Controller]}
                      loop={true}
                      navigation={{
                        prevEl: navigationPrevRef.current,
                        nextEl: navigationNextRef.current,
                      }}
                      onBeforeInit={(swiper) => {
                        swiper.params.navigation.prevEl =
                          navigationPrevRef.current;
                        swiper.params.navigation.nextEl =
                          navigationNextRef.current;
                      }}
                    >
                      <div className="swiper-wrapper">
                        {frontPageNews?.length > 0 ? (
                          <>
                            {frontPageNews?.map((news) => (
                              <SwiperSlide key={news.id}>
                                <div className="swiper-slide">
                                  <div className="image-slide">
                                    <Link
                                      to={`/${routeName(
                                        news.news_bulletin?.name,
                                        news.id,
                                        news.post_name
                                      )}`}
                                    >
                                      <img
                                        // src={image(news)}
                                        src={
                                          news.image_high
                                            ? image(news)
                                            : "placeholder-img.png"
                                        }
                                        className={
                                          news.image_high
                                            ? ""
                                            : "img-fluid placeholder-img w-50"
                                        }
                                        alt={`${news.title}`}
                                      />
                                    </Link>
                                  </div>
                                </div>
                              </SwiperSlide>
                            ))}{" "}
                          </>
                        ) : (
                          <SwiperSlide>
                            <div className="swiper-slide">
                              <div className="image-slide">
                                <img
                                  src="/placeholder-img.png"
                                  alt="placeholder-img.png"
                                  className="img-fluid placeholder-img w-50"
                                  style={{ height: "500px" }}
                                />
                              </div>
                            </div>
                          </SwiperSlide>
                        )}
                      </div>
                    </Swiper>
                  </div>
                </div>
              </div>
            </div>
            {newsList !== undefined && (
              <div className="col-lg-4">
                {newsList?.length !== 0 ? (
                  newsList?.slice(0, 4).map((news) => (
                    <Link
                      to={`/${routeName(
                        news.news_bulletin?.name,
                        news.id,
                        news.post_name
                      )}`}
                      className="news-aside"
                      key={news.id}
                    >
                      <div className="aside-image">
                        <LazyLoadImage
                          src={
                            news.image_high
                              ? image(news)
                              : "placeholder-img.png"
                          }
                          alt={`${news.title}`}
                        />
                      </div>
                      <div className="aside-text">
                        <div className="tag-md mb-2">
                          {news.news_bulletin?.name}
                        </div>
                        <div className="mb-2" style={{ fontWeight: "500" }}>
                          {wordsCapping(news.title, 50)}
                        </div>
                        <div className="para-4 muted-text">
                          By {news.user?.name}, <BsCalendarCheck className="ml-1" /> {date(news)}
                        </div>
                      </div>
                    </Link>
                  ))
                ) : (
                  <div className="news-aside">
                    <div className="aside-image">
                      <img src="/placeholder-img.png" alt="" />
                    </div>
                    <div className="aside-text">
                      <div className="mb-2" style={{ fontWeight: "500" }}>
                        commng soon
                      </div>
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </section>
    </>
  );
}
export default BreakingNews;
