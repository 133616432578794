import { useEffect, useState, useRef } from "react";
import "./Podcast.css";

import TrendingNews from "../../Components/HomePage/TrendingNews";
import NewsLetterForm from "../../Components/BulletinsNews/NewsLetterForm";
import NewEpisode from "../../Components/Podcast/NewEpisode";
import MoreEpisodes from "../../Components/Podcast/MoreEpisodes";
import GoogleAds from "../../Components/GoogleAds/Ad-long";

import { getPodcasts } from "../../api/endpoints";


import { CircularProgress } from "@mui/material";
import { Box } from "@mui/system";

function Podcast() {

    const [isLoading, setLoading] = useState(true);
    const [moreNewsLoader, setMoreNewsLoader] = useState(false);
    const [podcastEpisodes, setPodcastEpisodes] = useState([]);
    const [pageNo, setPageNo] = useState(1)
    const [totalPages, setTotalPages] = useState(1)
    const [newEpisodes, setNewEpisodes] = useState([]);

    async function getPodcastsList() {
        try {
            const { status, data: { data, totalPages } } = await getPodcasts();
            if (status === 200) {
                setNewEpisodes(data);
                setPodcastEpisodes(data.slice(6, data.length));
                setTotalPages(totalPages)
                setLoading(false);
            } else {
                setLoading(false);
            }
        } catch (error) {
            setLoading(false);
        }
    }

    useEffect(() => {
        getPodcastsList();
    }, []);

    useEffect(() => {
        async function getMorePodcasts() {
            setMoreNewsLoader(true)
            try {
                const { status, data: { data, totalPages } } = await getPodcasts(pageNo);
                if (status === 200) {
                    setPodcastEpisodes(pageNo === 1 ? data.slice(6, data.length) : data);
                    setTotalPages(totalPages)
                    setMoreNewsLoader(false);
                } else {
                    setMoreNewsLoader(false);
                }
            } catch (error) {
                setMoreNewsLoader(false);
            }
        }
        getMorePodcasts();
    }, [pageNo]);

    if (isLoading) {
        return <>
            <Box sx={{
                display: "flex",
                height: "60vh",
                alignItems: "center",
                justifyContent: "center",
                opacity: "0.8",
                zIndex: "1000",
            }}>
                <CircularProgress sx={{ color: "white" }} />
            </Box>
        </>
    }

    return <>
        <TrendingNews />
        <NewEpisode newEpisodes={newEpisodes} />
        <NewsLetterForm />
        <MoreEpisodes 
            loading={moreNewsLoader}
            podcast={podcastEpisodes} 
            page={setPageNo}
            currentPage={pageNo-1}
            totalPages={totalPages}
        />
        <GoogleAds />
    </>
}

export default Podcast;