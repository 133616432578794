function VideoFrame({videoLink}){
    return <>
    <iframe
        src={`https://www.youtube.com/embed/${videoLink}`}
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        ></iframe>
    </>
}
export default VideoFrame;