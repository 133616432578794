import React, { useEffect, useState } from "react";
import twitter from "../../images/twitter-color.svg";
import youtube from "../../images/youtube-color.svg";
import facebook from "../../images/facebook-color.svg";
import "./Footer.css";
import { IoLocationOutline } from "react-icons/io5";
import { BsTelephone } from "react-icons/bs";
import { AiOutlineMail } from "react-icons/ai";
import { format } from "date-fns";
import { Link } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { assets_images } from "../../constants";
import { AdLong } from "../Ad-long/AdLong";
import { getHeaderFooterData } from "../../api/endpoints";
import { useSelector } from "react-redux";

export const Footer = () => {
  const year = format(new Date(), "yyyy");
  const siteData = useSelector((state)=> state.headerFooterData)
  return (
    <>
      <footer className="footer secondary-bg">
        <div className="container spacer-y">
          <div className="footer-row">
            <div className="footer-col">
              <Link to="/">
                <LazyLoadImage
                  src={`${assets_images}/${siteData?.FOOTER_CONTENT?.value?.logo?.image_high}`}
                  className="img-fluid"
                  alt="popsmoke logo"
                  style={{ width: "279px" }}
                />
              </Link>
              {/* <p className="mt-3">
                Pop Smoke Media began as a way to bring laughter to troops world
                wide in 2015. As our presence grew, so did our responsibility to
                use our voice to affect positive change. It is our mission to
                entertain, educate and advocate for the military and veteran
                community.
              </p> */}
              <p className="mt-3">
                {siteData?.FOOTER_CONTENT?.value?.text?.main &&
                  siteData?.FOOTER_CONTENT?.value?.text?.main}
              </p>
            </div>
            <div className="footer-col">
              <h3>Quick Links</h3>
              <ul className="footer-list mt-3">
                <li>
                  <Link to="/">Newsroom</Link>
                </li>
                <li>
                  <Link to="/category/intel">Intel</Link>
                </li>
                <li>
                  <Link to="/category/industry">Industry</Link>
                </li>
                <li>
                  <Link to="/category/featured">Featured</Link>
                </li>
                <li>
                  <Link to="/category/podcast">Podcast</Link>
                </li>
                <li>
                  <Link to="/about-us">About Us</Link>
                </li>
                <li>
                  <Link to="/resources">Resources</Link>
                </li>
                <li>
                  <Link to="/our-sponsors">Our Sponsors</Link>
                </li>
              </ul>
            </div>
            {/* <div className="footer-col">
              <h3>Contact Information</h3>
              <div className="footer-contact mt-3">
                <div className="footer-blk">
                  <div className="footer-cont-image">
                    <IoLocationOutline />
                  </div>
                  <div className="footer-count-text">
                    {siteData?.FOOTER_CONTENT?.value?.contact_info?.address &&
                      siteData?.FOOTER_CONTENT?.value?.contact_info?.address}
                  </div>
                </div>
              </div>
              <div className="footer-contact">
                <a name="phone" href="tel:+17605982525">
                  <div className="footer-cont-image">
                    <BsTelephone />
                  </div>
                  <div className="footer-cont-text">
                    {siteData?.FOOTER_CONTENT?.value?.contact_info?.phone_no &&
                      siteData?.FOOTER_CONTENT?.value?.contact_info?.phone_no}
                  </div>
                </a>
              </div>
              <div className="footer-contact">
                <a
                  name="mail"
                  href={`mailto:${
                    siteData?.FOOTER_CONTENT?.value?.contact_info?.email &&
                    siteData?.FOOTER_CONTENT?.value?.contact_info?.email
                  }`}
                >
                  <div className="footer-cont-image">
                    <AiOutlineMail />
                  </div>
                  <div className="footer-cont-text">
                    {siteData?.FOOTER_CONTENT?.value?.contact_info?.email &&
                      siteData?.FOOTER_CONTENT?.value?.contact_info?.email}
                  </div>
                </a>
              </div>
            </div> */}
            <div className="footer-col">
              <h3>Follow Us</h3>
              <ul className="footer-socials list-unstyled mt-3">
                <li>
                  <a
                    href={`${
                      siteData?.SOCIAL_LINKS?.value?.facebook?.url &&
                      siteData?.SOCIAL_LINKS?.value?.facebook?.url
                    }`}
                    target="/"
                  >
                    <LazyLoadImage
                      src={facebook}
                      alt="facebook"
                      style={{ height: "25px", width: "25px" }}
                    />
                  </a>
                </li>
                <li>
                  <a
                    name="twitter"
                    href={`${
                      siteData?.SOCIAL_LINKS?.value?.twitter?.url &&
                      siteData?.SOCIAL_LINKS?.value?.twitter?.url
                    }`}
                    target="/"
                  >
                    <LazyLoadImage
                      src={twitter}
                      alt="twitter"
                      style={{ height: "25px", width: "25px" }}
                    />
                  </a>
                </li>
                <li>
                  <a
                    href={`${
                      siteData?.SOCIAL_LINKS?.value?.instagram?.url &&
                      siteData?.SOCIAL_LINKS?.value?.instagram?.url
                    }`}
                    target="/"
                  >
                    <LazyLoadImage
                      src="https://upload.wikimedia.org/wikipedia/commons/thumb/a/a5/Instagram_icon.png/2048px-Instagram_icon.png"
                      alt="instagram"
                      style={{ height: "25px", width: "25px" }}
                    />
                  </a>
                </li>
                <li>
                  <a
                    href={`${
                      siteData?.SOCIAL_LINKS?.value?.youtube?.url &&
                      siteData?.SOCIAL_LINKS?.value?.youtube?.url
                    }`}
                    target="/"
                  >
                    <LazyLoadImage
                      src={youtube}
                      alt="youtube"
                      style={{ height: "25px", width: "25px" }}
                    />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </footer>
      <div className="copyright">
        <div className="container">
          <p className="para-3 text-center">
            Copyright © <span className="year">{year}</span>{" "}
            <Link to="/">www.popsmokemedia.com</Link>. All Rights Reserved.
          </p>
        </div>
      </div>

      <div className="web-ads-design ads-lt">
        <AdLong />
      </div>
      <div className="web-ads-design ads-rt">
        <AdLong />
      </div>
    </>
  );
};
