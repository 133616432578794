import { ContactForm } from "../../Components/ContactForm/ContactForm";
import { SubmitStory } from "../../Components/SubmitStory/SubmitStory";
import { Subscribe } from "../../Components/Subscribe/Subscribe";
import { TrendingNews } from "../../Components/Trending News/TrendingNews";
import ConatctNewsLetterForm from "./ConatctNewsLetterForm";

export const ContactPage = () => {
  return (
    <div>
      {/* <TrendingNews /> */}
      <ConatctNewsLetterForm />
      {/* <SubmitStory /> */}
      <ContactForm />
      <Subscribe />
    </div>
  );
};
