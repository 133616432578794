import React from "react";
import { Link } from "react-router-dom";
import bomb from "../../images/bomb.png";
import { LazyLoadImage } from "react-lazy-load-image-component";

export const ContactForAd = () => {
  return (
    <section className="contact-for-ad">
      <div className="container spacer-y pb-0">
        <div className="contact-ad-wrapper">
          <div className="row g-3 gx-lg-4 justify-content-md-between align-items-center">
            <div className="col-lg-7 col-md-8 order-2 order-md-1">
              <p className="heading-md mb-3 fw-600">
                Want to see your products here, or on the Smoke Pit Podcast?
                Send us an email or DM us!
              </p>
              <p className="text-muted">Additional Sponsors:</p>
              <ul className="text-muted mb-4">
                <li>Plan B</li>
                <li>Brovets and III% Everywhere</li>
              </ul>
              <Link name="contactUs" to='/contact-us' className="theme-button">Contact Us</Link>
            </div>
            <div className="col-lg-3 col-md-4 order-1 order-md-2 text-center text-md-end">
              <LazyLoadImage
                src={bomb}
                className="img-fluid"
                alt="advertisement"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
