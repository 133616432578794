// export const port = `http://192.168.1.47:3883/popsmoke-api`;
// export const port = `http://indeedtraining.in:7979/popsmoke-api`;
export const port = `https://api.popsmokemedia.com/popsmoke-api`;


// export const img = `https://indeedtraining.in/popsmoke-api/public/images`;
// export const img = `https://indeedtraining.in/popsmoke-images`;

// export const img = `https://indeedtraining.in/popsmoke-api-new-setup/public/images/`;
// export const assets_images = `https://indeedtraining.in/popsmoke-api-new-setup/public/assets/`;
// export const videoUrl = `https://indeedtraining.in/popsmoke-api-new-setup/public/videos`;


// export const img = `http://192.168.29.193:3883/public/images/`;
// export const assets_images = `http://192.168.29.193:3883/assets/`;
// export const videoUrl = `http://192.168.29.193:3883/videos`;

export const img = `https://api.popsmokemedia.com/images/`;
export const assets_images = `https://api.popsmokemedia.com/assets/`;
export const videoUrl = `https://api.popsmokemedia.com/videos`;

export const DOMAIN_URL = "https://popsmokemedia.com";

const options = { year: "numeric", month: "long", day: "numeric" };

export const date = (data) => {
  const _date = data.published_date?.split('T')[0];
  return new Date(_date).toLocaleDateString("en-US", options);
};

export function getUniqueListBy(arr, key) {
  if (arr.length !== 0) {
    return [...new Map(arr.map((item) => [item[key], item])).values()];
  } else {
    return [];
  }
}

export const routeName = (name, id, postname = "") => {
  // return `${name?.replace(/ /g, "")?.toLowerCase()}/${id}/${postname}`;
  return postname;
};

export const ytLink = (url) => {
  if (url.includes("watch?")) {
    const separateVEquesTo = url.split('v=');
    const seprateVID = separateVEquesTo[1].split('&');
    return `https://www.youtube.com/embed/${seprateVID[0]}`;
  } else if (url.includes("embed")) {
    return url;
  } else {
    const arr = url.split("/");
    arr?.splice(3, 0, "embed");
    arr[2] = "youtube.com";
    return arr.join("/");
  }
};

export const image = (data) => {
  if (data?.image_high) {
    return `${img}/${data?.image_high}`;
  } else {
    return "/placeholder-img.png";
  }
};
export const video = (data) => {
  return `${videoUrl}/${data?.news_video_link}`;
};

export const videoPodcast = (data) => {
  
  try {
    return `${videoUrl}/${data?.upload_video}`;
  } catch (error) {}
};

export function shuffleArray(arr) {
  let array = [...arr];
  for (var i = array.length - 1; i > 0; i--) {
    // Generate random number
    var j = Math.floor(Math.random() * (i + 1));
    var temp = array[i];
    array[i] = array[j];
    array[j] = temp;
  }

  return array;
}

export const randomIndex = (data) => {
  return Math.floor(Math.random() * data.slice(0, 20).length);
};

export const DEFAULT_VALUES = {
  SOCIAL_URL: {
    FB: "https://www.facebook.com/pop.smoke.official",
    YT: "https://www.youtube.com/channel/UCgQeBeaGvh5pWvH5ZgQ-85A/videos",
    IG: "https://www.instagram.com/pop.smoke.media",
    TWITTER: "https://twitter.com/PopSmokeMedia",
  },
  COUNTRY_ISO: "us",
  COUNTRY_DIAL_CODE: "+1",
};

export const ROUTE_SLUGS = [
  "top",
  "intel",
  "industry",
  "featured",
  "breaking-news",
  "world-news",
  "breakin-gnews",
  "oped-industry",
  "trending-news",
];

export const VALIDATION_RULES = {
  NAME: /^[A-Z 0-9]{2,}$/i,
  PASSWORD:
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
};

export const VALIDATION_MESSAGE = {
  REQUIRED: {
    MESSAGE: `This field is required.`,
  },
  NAME: {
    REQUIRED: `Name is required.`,
    REGEX_RULES: `Name must contain only letters.`,
    MAX_LENGTH: `Name can't be more then 30 charachters.`,
  },
  EMAIL: {
    REQUIRED: `Email address is required.`,
    INVALID: `Email address is invalid.`,
    UNIQUE: `This email is already exist. `,
  },
  PASSWORD: {
    REQUIRED: `Password is required.`,
    REGEX_RULES: `Password must contain at least 8 characters, one uppercase letter, one lowercase letter, one number and one special character.`,
  },
  IMAGE: {
    REQUIRED: `Image is required.`,
    FORMAT: `Only *.jpeg, *.jpg, *.png, images are allowed.`,
  },
  PINCODE: {
    MAX_LENGTH: `Zipcode can't be longer than 10 characters`,
  },
  ADDRESS: {
    MAX_LENGTH: `Address can't be longer than 50 characters`,
  },
  CITY: {
    MAX_LENGTH: `City can't be longer than 30 characters`,
  },
  ROLE: {
    REQUIRED: `Role is required.`,
  },
};

export const AUDIO_PODCASTS_LINKS = {
  ON_ITUNES: "https://podcasts.apple.com/us/podcast/the-smoke-pit/id1441222048",
  ON_SPOTIFY: "https://www.popsmokemedia.com/smoke-pit-podcast",
  ON_TUNEIN: "https://tunein.com/podcasts/Comedy/The-Smoke-Pit-p1183494",
  ON_GOOGLE: "https://tunein.com/podcasts/Comedy/The-Smoke-Pit-p1183494",
};

export const SPONSERS_LINKS = {
  MASSIF: "https://www.massif.com",
  GOULDUSA: "https://gouldusa.com",
  BLOCTACTICAL: "https://www.bloctactical.com",
  GUNNER: "https://gunner.com",
};

export const APP_SETTINGS = {
  PHONE_NO: +17605982525,
  EMAIL: "info@popsmokemedia.com",
  ADDRESS: "Fredericksburg, VA 22408, US",
  TWITTER_SHARE: `https://twitter.com/share?url=${DOMAIN_URL}`,
  FB_SHARE: `https://www.facebook.com/sharer/sharer.php?u=${DOMAIN_URL}`,
  INSTA_SHARE: `https://www.instagram.com/?url=${DOMAIN_URL}`,
};

export const USERS = {
  DANIEL_SHARP: {
    FB: "https://www.facebook.com/daniel.sharp.71404",
    LINKEDIN: "https://www.linkedin.com/in/pop-smoke",
    INSTA: "https://www.instagram.com/pop_smoke_official",
    TWITTER: "https://twitter.com/Pop_Smoke7",
  },
  JUSTIN_EGGEN: {
    FB: "https://www.facebook.com/justin.eggen",
    LINKEDIN: "",
    INSTA: "https://www.instagram.com/justinthomaseggen/?hl=en",
    TWITTER: "",
  },
};

export const GOOGLE_ADDS_KEY ={
  DATA_CLIENT:'pub-1933184333014036',
  DATA_SLOT:'f08c47fec0942fa0',
}
export const TWEET_EMBEDED_SCRIPT_URL = "https://platform.twitter.com/widgets.js";
