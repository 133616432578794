import React from "react";
import { useEffect, useState } from "react";
import videoPlay from "../../images/video-play.svg";
import ytVideo2 from "../../images/yt-video-2.png";
import ytVideo3 from "../../images/yt-video-3.png";
import ytVideo4 from "../../images/yt-video-4.png";
import ytVideo5 from "../../images/yt-video-5.png";
import { LazyLoadImage } from "react-lazy-load-image-component";
import VideoFrame from "./VideoFrame";

export const LatestVideos = () => {
  const [vidoesList, setVideosList] = useState([
      {videoID:'V--XIgKyayw', videoTitle:`What's Your Tactical IQ'`},
      {videoID:'4G2ICC9RBJI', videoTitle:'Shit got weird'},
      {videoID:'GxfPnPeDiHM', videoTitle:'The dominant one'},
      {videoID:'Z-j5b941urI', videoTitle:'A typical day at the VA'},
      {videoID:'DLPfy4-Vfuo', videoTitle:'Full metal jacket in space'},
      {videoID:'AyOZY-q9b2o', videoTitle:'Friends don’t let friends re-enlist'},
      {videoID:'2iT5OJJJcio', videoTitle:'Gruntfellas'},
  ]);
  
  // const YT_THUMBNAIL = 'https://i.ytimg.com/vi/{VID}/maxresdefault.jpg'
  const YT_THUMBNAIL = 'https://img.youtube.com/vi/{VID}/0.jpg'
  const [selectedVideoIndex, setSelectedVideoIndex] = useState(0);

  return (
    <section className="latest-videos">
      <div className="container spacer-y pb-0">
        <div className="section-header">
          <h3>Our Oscar Worthy Performances</h3>
          <hr />
        </div>
        <div className="row g-3 mt-30">
          <div className="col-lg-7">
            <div className="video-big yt-video">
              {/* <div className="play-button">
                <LazyLoadImage src={videoPlay} alt="play button" />
              </div> */}
              <VideoFrame videoLink={vidoesList[selectedVideoIndex].videoID} />
            </div>
          </div>
          <div className="col-lg-5">
            <div className="more-videos">
              { vidoesList.map((item, index)=>
                ( 
                  <span className="video-aside video-lists-li about-thumb" onClick={()=>setSelectedVideoIndex(index)}>
                    <div className="aside-image">
                      <div className="play-button">
                        <LazyLoadImage src={videoPlay} alt="play button" />
                      </div>
                      <LazyLoadImage src={YT_THUMBNAIL.replace('{VID}', item.videoID)} alt="news" />
                    </div>
                    <div className="aside-text">
                      <h5 className="mb-2">
                      {item.videoTitle}
                      </h5>
                    </div>
                  </span>
                )
              )}
              {/* <a name="side video" href=" " className="video-aside">
                <div className="aside-image">
                  <div className="play-button">
                    <LazyLoadImage src={videoPlay} alt="play button" />
                  </div>
                  <LazyLoadImage src={ytVideo2} alt="news" />
                </div>
                <div className="aside-text">
                  <h5 className="mb-2">
                    Ever Wonder what happen when two Marine vet go to heaven?
                  </h5>
                </div>
              </a>
              <a name="video side" href=" " className="video-aside">
                <div className="aside-image">
                  <div className="play-button">
                    <LazyLoadImage src={videoPlay} alt="news" />
                  </div>
                  <LazyLoadImage src={ytVideo3} alt="news" />
                </div>
                <div className="aside-text">
                  <h5 className="mb-2">Full Matel Jacket in Space</h5>
                </div>
              </a>
              <a name="video side" href=" " className="video-aside">
                <div className="aside-image">
                  <div className="play-button">
                    <LazyLoadImage src={videoPlay} alt="news" />
                  </div>
                  <LazyLoadImage src={ytVideo4} alt="news" />
                </div>
                <div className="aside-text">
                  <h5 className="mb-2"> Shit Get Weird</h5>
                </div>
              </a>
              <a name="video side" href=" " className="video-aside">
                <div className="aside-image">
                  <div className="play-button">
                    <LazyLoadImage src={videoPlay} alt="news" />
                  </div>
                  <LazyLoadImage src={ytVideo5} alt="news" />
                </div>
                <div className="aside-text">
                  <h5 className="mb-2">The Dominent One</h5>
                </div>
              </a>
              <a name="video side" href=" " className="video-aside">
                <div className="aside-image">
                  <div className="play-button">
                    <LazyLoadImage src={videoPlay} alt="news" />
                  </div>
                  <LazyLoadImage src={ytVideo2} alt="news" />
                </div>
                <div className="aside-text">
                  <h5 className="mb-2">
                    Ever Wonder what happen when two Marine vet go to heaven ?
                  </h5>
                </div>
              </a>
              <a name="video side" href=" " className="video-aside">
                <div className="aside-image">
                  <div className="play-button">
                    <LazyLoadImage src={videoPlay} alt="news" />
                  </div>
                  <LazyLoadImage src={ytVideo3} alt="news" />
                </div>
                <div className="aside-text">
                  <h5 className="mb-2">Full Matel Jacket in Space</h5>
                </div>
              </a>
              <a name="video side" href=" " className="video-aside">
                <div className="aside-image">
                  <div className="play-button">
                    <LazyLoadImage src={videoPlay} alt="news" />
                  </div>
                  <LazyLoadImage src={ytVideo4} alt="news" />
                </div>
                <div className="aside-text">
                  <h5 className="mb-2"> Shit Get Weird</h5>
                </div>
              </a>
              <a name="video side" href=" " className="video-aside">
                <div className="aside-image">
                  <div className="play-button">
                    <LazyLoadImage src={videoPlay} alt="news" />
                  </div>
                  <LazyLoadImage src={ytVideo5} alt="news" />
                </div>
                <div className="aside-text">
                  <h5 className="mb-2">The Dominent One</h5>
                </div>
              </a> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
