import { useEffect } from "react";
import { subscribe } from "../../../api/endpoints";

import { useSelector, useDispatch } from "react-redux";
import { VALIDATION_RULES, VALIDATION_MESSAGE } from "../../../constants";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import {
  setRequestInProgress,
  setRequestSuccess,
  setRequestFailure,
  setRequestInit,
} from "../../../redux/Slices/RequestFormSlice";

let formTypePayload = { type: "worldNewsLetter" };

const validationSchema = yup.object().shape({
  email: yup
    .string()
    .required(VALIDATION_MESSAGE.EMAIL.REQUIRED)
    .email(VALIDATION_MESSAGE.EMAIL.INVALID),
});

function WorldNewsLetterForm() {
  const { worldNewsLetter } = useSelector((state) => state.requestForm);
  const dispatch = useDispatch();

  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm({
    mode: "all",
    resolver: yupResolver(validationSchema),
  });

  const handleFormSubmit = async (formData) => {
    try {
      dispatch(setRequestInProgress(formTypePayload));
      const {
        status,
        data: { msg },
      } = await subscribe(formData.email);
      if (status === 200) {
        formTypePayload = { ...formTypePayload, successMessage: msg };
        dispatch(setRequestSuccess(formTypePayload));
        reset();
        setTimeout(() => {
          dispatch(setRequestInit(formTypePayload));
        }, 3000);
      } else {
        formTypePayload = { ...formTypePayload, errorMessage: msg };
        dispatch(
          setRequestFailure("Unable to send your request. Please try  again.")
        );
        dispatch(setRequestFailure(formTypePayload));
        setTimeout(() => {
          dispatch(setRequestInit({ type: "worldNewsLetter" }));
        }, 3000);
      }
    } catch (error) {
      const {
        response: {
          data: { msg },
        },
      } = error;
      formTypePayload = { ...formTypePayload, errorMessage: msg };
      dispatch(setRequestFailure(formTypePayload));
      setTimeout(() => {
        dispatch(setRequestInit({ type: "worldNewsLetter" }));
      }, 3000);
    }
  };

  return (
    <>
      <div className="keep-in-touch mt-30 blue-bg">
        <div>
          <p className="para-1">Keep In Touch</p>
          <p className="para-3">
            We will keep you updated and informed on new episodes, products, and
            all the things.
          </p>

          <form onSubmit={handleSubmit(handleFormSubmit)} noValidate>
            <input
              {...register("email")}
              readOnly={worldNewsLetter?.loader}
              type="email"
              name="email"
              className="form-control theme-form mt-3 text-white"
              placeholder="Email Address"
            />
            <div className="text-start">
              {errors.email && (
                <span style={{ color: "#fff", fontSize: "14px" }}>
                  {errors.email.message}
                </span>
              )}

              {Object.keys(errors).length === 0 &&
              worldNewsLetter?.errorMessage ? (
                <span className="text-white">
                  {worldNewsLetter?.errorMessage}
                </span>
              ) : (
                ""
              )}

              {Object.keys(errors).length === 0 &&
              worldNewsLetter?.successMessage ? (
                <span className="text-white">
                  {worldNewsLetter?.successMessage}
                </span>
              ) : (
                ""
              )}
            </div>
            <button
              disabled={worldNewsLetter?.loader}
              type="submit"
              className="secondary-button w-100 mt-3"
            >
              {worldNewsLetter?.loader ? "Please wait..." : "Subscribe"}
            </button>
          </form>
        </div>
      </div>
    </>
  );
}

export default WorldNewsLetterForm;
