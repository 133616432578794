import axios from "axios";
import { port } from "../constants";

export const getNewsBulletin = async () => {
  return await axios.get(`${port}/newsBulletinList`);
};

export const getBannerPagenNews = async () => {
  return await axios.get(`${port}/getBannerPage`);
};

export const getTopStories = async () => {
  return await axios.get(`${port}/getTopStories`);
};
export const fetchTendingNews = async () => {
  return await axios.get(`${port}/getTrendingNews`);
};

export const getRandomNewsList = async () => {
  return await axios.get(`${port}/getRandomNews`);
};

export const getWorldNewsList = async () => {
  return await axios.get(`${port}/getWorldNews`);
};

export const getPodcasts = async (pageNo = 1) => {
  return await axios.get(`${port}/getPodcasts?page=${pageNo}`);
};

export const fetchNewsByBulletin = async (bulletin, pageNo = 1) => {
  return await axios.get(
    `${port}/fetchNewsByBulletin/${bulletin}?page=${pageNo}`
  );
};

export const fetchNewsByIdAndSlug = async (id, slug) => {
  return await axios.get(`${port}/fetchNewsByIdAndSlug/${id}/${slug}`);
};

export const fetchNewsBySlug = async (slug) => {
  return await axios.get(`${port}/fetchNewsBySlug/${slug}`);
};

export const fetchLatestNewsByBulletinId = async (id, avoidNewsId) => {
  return await axios.get(
    `${port}/fetchLatestNewsByBulletinId/${id}/${avoidNewsId}`
  );
};

export const getAllNews = async () => {
  return await axios.get(`${port}/fetchAllNews`);
};

export const getNewsByName = async (id) => {
  return await axios.get(`${port}/fetchNewsListByName?id=${id}`);
};

export const getNewsByNamePage = async (id) => {
  return await axios.get(`${port}/fetchNewsListByName/${id}`);
};

export const getSponserList = async (id) => {
  return await axios.get(`${port}/getSponsorsList`);
};

export const getPodcast = async (page) => {
  return await axios.get(`${port}/fetchPodcastList?page=${page}`);
};

export const getPodcastList = async () => {
  return await axios.get(`${port}/fetchPodcast`);
};

export const getPodcastById = async (id, slug) => {
  return await axios.get(`${port}/fetchPodcastById/${id}/${slug}`);
};
export const getPodcastBySlug = async (slug) => {
  return await axios.get(`${port}/fetchPodcastBySlug/${slug}`);
};

export const searchNews = async (query, page = 1) => {
  return await axios.get(`${port}/search?query=${query}&page=${page}`);
};

export const getHeaderFooterData = async (query) => {
  return await axios.get(`${port}/getSiteData`)
};

export const subscribe = async (data) => {
  return await axios.post(
    `${port}/subscribe`,
    { email: data },
    {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    }
  );
};

export const addContact = async (data) => {
  return await axios.post(`${port}/addContactUs`, data);
};
