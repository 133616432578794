import { useRef } from "react";

import { Link } from "react-router-dom";
import { date, videoPodcast } from "../../constants";

import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";
import { BsCalendarCheck } from "react-icons/bs";
import { Swiper, SwiperSlide } from "swiper/react";
import { Controller, Navigation } from "swiper";
import ReactPlayer from "react-player/lazy";
import {
  matchAcastUrl,
  acastEmbededUrl,
  wordsCapping,
} from "../../utils/helpers";

import { CircularProgress } from "@mui/material";
import { Box } from "@mui/system";

function NewEpisode(props) {
  const { newEpisodes } = props;
  const navigationPrevRef = useRef(null);
  const navigationNextRef = useRef(null);

  function createMarkup(data) {
    return {
      __html: `${data?.description.slice(0, 430)}${
        data?.description.length > 430 ? "...." : ""
      }`,
    };
  }

  return (
    <>
      <section className="top-stories">
        <div className="container spacer-y">
          <div className="section-header">
            <h3>NEWEST EPISODES</h3>
            <hr />
            <div className="slider-nav-buttons">
              <div className="slide-button story-prev" ref={navigationPrevRef}>
                <AiOutlineArrowLeft />
              </div>
              <div className="slide-button story-next" ref={navigationNextRef}>
                <AiOutlineArrowRight />
              </div>
            </div>
          </div>
          {newEpisodes?.length > 0 ? (
            <div className="swiper story-slider mt-30 mb-40">
              <div className="swiper-wrapper">
                <Swiper
                  speed={1000}
                  spaceBetween={10}
                  grabCursor={true}
                  modules={[Navigation, Controller]}
                  loop={true}
                  navigation={{
                    prevEl: navigationPrevRef.current,
                    nextEl: navigationNextRef.current,
                  }}
                  onBeforeInit={(swiper) => {
                    swiper.params.navigation.prevEl = navigationPrevRef.current;
                    swiper.params.navigation.nextEl = navigationNextRef.current;
                  }}
                >
                  {newEpisodes.slice(0, 3).map((podcast, index) => (
                    <SwiperSlide key={index}>
                      <div className="swiper-slide">
                        <div className="row g-3 align-items-center">
                          <div className="col-lg-5 order-2 order-lg-1">
                            <div className="story-text p-summary">
                              <Link
                                to={`/podcast/${podcast.post_name}`}
                              >
                                <h3 className="mb-3">
                                  {wordsCapping(podcast.title, 80)}
                                </h3>
                              </Link>
                              {wordsCapping(podcast.summary, 300)}
                              <div className="mt-30 d-flex align-items-center gap-3">
                                <span className="para-4 muted-text">
                                  By {podcast.user?.name}
                                </span>
                                <p>
                                  <BsCalendarCheck />{" "}
                                  <span className="para-4 muted-text">
                                    {date(podcast)}
                                  </span>
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-7 order-1 order-lg-2">
                            {podcast?.podcast_video_link !== null ? (
                              <iframe
                                width={"100%"}
                                // height="350"
                                src={
                                  matchAcastUrl(podcast.podcast_video_link)
                                    ? acastEmbededUrl(
                                        podcast.podcast_video_link
                                      )
                                    : podcast.podcast_video_link
                                }
                                title={podcast.title}
                                frameborder="0"
                                allowFullScreen
                              ></iframe>
                            ) : (
                              <ReactPlayer
                                className="img-fluid"
                                url={podcast.podcast_yt_video_link}
                              />
                            )}
                          </div>
                          {/* <div className="col-lg-7 order-1 order-lg-2">
                                                {!videoPodcast(podcast) ? (
                                                    <Box
                                                        sx={{
                                                            display: "flex",
                                                            height: "60vh",
                                                            alignItems: "center",
                                                            justifyContent: "center",
                                                            opacity: "0.8",
                                                            zIndex: "1000",
                                                        }}
                                                    >
                                                        <CircularProgress sx={{ color: "white" }} />
                                                    </Box>
                                                ) : (
                                                    <ReactPlayer
                                                        className="img-fluid"
                                                        url={podcast.podcast_yt_video_link}
                                                    />
                                                )}
                                            </div> */}
                        </div>
                      </div>
                    </SwiperSlide>
                  ))}
                </Swiper>
              </div>
            </div>
          ) : (
            <div className="row g-3 align-items-center justify-content-center">
              <div className="col-lg-5 order-2 order-lg-1">
                <div className="text-center">
                  <img
                    src="/placeholder-img.png"
                    className="img-fluid placeholder-img w-50"
                    alt=""
                  />
                  <h3 className="mb-3 text-center">Coming Soon</h3>
                </div>
              </div>
            </div>
          )}
          <div className="row justify-content-center g-3">
            {newEpisodes.slice(3, 6).map((podcast, index) => (
              <div key={index} className="col-lg-4 col-md-6">
                <Link to={`/podcast/${podcast.post_name}`}>
                  <div className="story-cards">
                    <div className="story-card-image">
                      {podcast?.podcast_video_link !== null ? (
                        <iframe
                          width={"100%"}
                          height="250"
                          src={
                            matchAcastUrl(podcast.podcast_video_link)
                              ? acastEmbededUrl(podcast.podcast_video_link)
                              : podcast.podcast_video_link
                          }
                          title="Being a CIA Secret Squirrel in a Nutshell"
                          frameborder="0"
                          allowFullScreen
                        ></iframe>
                      ) : (
                        <ReactPlayer
                          className="img-fluid"
                          url={podcast.podcast_yt_video_link}
                        />
                      )}
                      {/* <ReactPlayer
                                            url={podcast.podcast_yt_video_link}
                                            className="img-fluid"
                                            width="100%"
                                            alt=""
                                        /> */}
                    </div>

                    <div className="story-card-text">
                      <h5>{wordsCapping(podcast.title)}</h5>
                      <p className="para-4">
                        By {podcast.user?.name} | <BsCalendarCheck className="ml-1" /> {date(podcast)}{" "}
                      </p>
                    </div>
                  </div>
                </Link>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
}

export default NewEpisode;
