import React, { useState, useEffect } from "react";
import "./TopStories.css";
import { getTopStories, getRandomNewsList } from "../../../api/endpoints";
import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";
import { BsCalendarCheck } from "react-icons/bs";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css/navigation";
import "swiper/css/controller";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Controller, Navigation } from "swiper";
import { useDispatch, useSelector } from "react-redux";
import {
  setTopStoriesNews,
  setRandomNews,
} from "../../../redux/Slices/NewsSlice";

import { date, image, routeName } from "../../../constants";
import { Link } from "react-router-dom";
import { Box, CircularProgress } from "@mui/material";
import { wordsCapping } from "../../../utils/helpers";

function TopStories() {
  const dispatch = useDispatch();
  const { topStoriesNews, randomNews } = useSelector((state) => state.news);
  const [isLoading, setLoading] = useState(
    topStoriesNews.length === 0 ? true : false
  );
  // const topNews = news.filter((e) => e.news_bulletin?.name == "Top");

  //ref for navigation button
  const navigationPrevRef = React.useRef(null);
  const navigationNextRef = React.useRef(null);

  useEffect(() => {
    if (topStoriesNews.length === 0) {
      getTopStoriesNews();
    }
    if (randomNews.length === 0) {
      getRandomNews();
    }
  }, []);

  async function getTopStoriesNews() {
    try {
      const {
        status,
        data: { data },
      } = await getTopStories();

      if (status === 200) {
        dispatch(setTopStoriesNews(data));
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  }

  async function getRandomNews() {
    try {
      const {
        status,
        data: { data },
      } = await getRandomNewsList();
      if (status === 200) {
        dispatch(setRandomNews(data));
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }

    // setLoading(false);
  }

  if (isLoading) {
    return (
      <>
        <Box
          sx={{
            display: "flex",
            height: "60vh",
            alignItems: "center",
            justifyContent: "center",
            opacity: "0.8",
            zIndex: "1000",
          }}
        >
          <CircularProgress sx={{ color: "white" }} />
        </Box>
      </>
    );
  }
  return (
    <section className="top-stories">
      <div className="container spacer-y">
        <div className="section-header">
          <h3>Top Stories</h3>
          <hr />
          <div className="slider-nav-buttons">
            <div className="slide-button story-prev" ref={navigationPrevRef}>
              <AiOutlineArrowLeft />
            </div>
            <div className="slide-button story-next" ref={navigationNextRef}>
              <AiOutlineArrowRight />
            </div>
          </div>
        </div>
        {topStoriesNews.length > 0 ? (
          <div className="swiper story-slider mt-30 mb-40">
            <div className="swiper-wrapper">
              <Swiper
                speed={1000}
                spaceBetween={10}
                grabCursor={true}
                modules={[Navigation, Controller]}
                loop={true}
                navigation={{
                  prevEl: navigationPrevRef.current,
                  nextEl: navigationNextRef.current,
                }}
                onBeforeInit={(swiper) => {
                  swiper.params.navigation.prevEl = navigationPrevRef.current;
                  swiper.params.navigation.nextEl = navigationNextRef.current;
                }}
              >
                {topStoriesNews.map((news) => (
                  <SwiperSlide key={news.id}>
                    <div className="swiper-slide">
                      <div className="row g-3 align-items-center">
                        <div className="col-lg-5 order-2 order-lg-1">
                          <div className="story-text">
                            <Link
                              to={`/${routeName(
                                news.news_bulletin?.name,
                                news.id,
                                news.post_name
                              )}`}
                            >
                              <div className="tag-md mb-2">Top</div>
                              <h3 className="mb-3">
                                {wordsCapping(news.title)}
                              </h3>
                            </Link>
                            <p className="muted-text">
                              {wordsCapping(news.summary, 150)}
                              <br />
                              <Link
                                to={`/${routeName(
                                  news.news_bulletin?.name,
                                  news.id,
                                  news.post_name
                                )}`}
                              >
                                Read more
                              </Link>
                            </p>
                            <div className="mt-30 d-flex align-items-center gap-3">
                              <span className="para-4 muted-text">
                                By {news.user?.name}
                              </span>
                              <p>
                                <BsCalendarCheck />{" "}
                                <span className="para-4 muted-text">
                                  {date(news)}
                                </span>
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-7 order-1 order-lg-2">
                          <Link
                            to={`/${routeName(
                              news.news_bulletin?.name,
                              news.id,
                              news.post_name
                            )}`}
                          >
                            <img
                              src={
                                news.image_high
                                  ? image(news)
                                  : "placeholder-img.png"
                              }
                              className={`img-fluid top-stories-image ${
                                news.image_high ? "" : "w-50"
                              }`}
                              alt={`${news?.title}`}
                            />
                          </Link>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </div>
        ) : (
          <div className="row g-3 align-items-center justify-content-center">
            <div className="col-lg-5 order-2 order-lg-1">
              <div className="text-center">
                <LazyLoadImage
                  src="/placeholder-img.png"
                  className="img-fluid placeholder-img w-50"
                  alt=""
                />
                <h3 className="mb-3 text-center">Coming Soon</h3>
              </div>
            </div>
          </div>
        )}

        <div className="row justify-content-center g-3">
          {randomNews.length > 0
            ? randomNews.map((news) => (
                <div className="col-lg-4 col-md-6" key={news.id}>
                  <Link
                    to={`/${routeName(
                      news.news_bulletin?.name,
                      news.id,
                      news.post_name
                    )}`}
                  >
                    <div className="story-cards">
                      <div className="story-card-image">
                        <LazyLoadImage
                          // src={image(news)}
                          src={
                            news.image_high
                              ? image(news)
                              : "/placeholder-img.png"
                          }
                          alt={`${news.title}`}
                          placeholderSrc="/placeholder-img.png"
                          effect="blur"
                        
                        />
                      </div>

                      <div className="story-card-text">
                        <div style={{ fontWeight: "600" }}>
                          {news.title.slice(0, 70).replace("&#39;", "'")}{" "}
                          {news?.title?.length > 70 && `....`}
                        </div>
                        <p className="para-4">
                          By {news.user?.name} |&nbsp;<BsCalendarCheck /> { date(news)}
                        </p>
                      </div>
                    </div>
                  </Link>
                </div>
              ))
            : ""}
        </div>
      </div>
    </section>
  );
}

export default TopStories;
