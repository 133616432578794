function PageNotFound(){
    return<>    
    <div className="container spacer-y">
        <div className="row div-404 ">
            <div className="col-md-6 text-center">
                <img src="/404.png" alt="404 not found" />
                <p className="mt-4">The page you are looking for may have been moved, deleted, or possibly never existed.</p>
            </div>
        </div>
    </div>
    </>
}

export default PageNotFound;