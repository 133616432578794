import { useEffect } from "react";
import { VALIDATION_RULES, VALIDATION_MESSAGE } from "../../constants";

import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useSelector, useDispatch } from "react-redux";
import { setRequestInProgress, setRequestSuccess, setRequestFailure, setRequestInit } from "../../redux/Slices/RequestFormSlice";
import { subscribe } from "../../api/endpoints";

let formTypePayload = { type: 'aboutNewsLetter' };

const validationSchema = yup.object().shape({
    email: yup.string()
        .required(VALIDATION_MESSAGE.EMAIL.REQUIRED)
        .email(VALIDATION_MESSAGE.EMAIL.INVALID),
});


function AboutNewsLetterForm() {
    const { aboutNewsLetter } = useSelector(state => state.requestForm);
    const dispatch = useDispatch();

    const { register, formState: { errors }, handleSubmit, reset, } = useForm({
        mode: 'all',
        resolver: yupResolver(validationSchema),
    });

    const handleFormSubmit = async (formData) => {
        try {
            dispatch(setRequestInProgress(formTypePayload));
            const { status, data: { msg } } = await subscribe(formData.email);
            if (status === 200) {
                formTypePayload = { ...formTypePayload, successMessage: msg };
                dispatch(setRequestSuccess(formTypePayload));
                reset();
                setTimeout(() => {
                    dispatch(setRequestInit(formTypePayload));
                }, 3000);
            } else {
                formTypePayload = { ...formTypePayload, errorMessage: "Unable to send your request. Please try  again." }
                dispatch(setRequestFailure(formTypePayload));
            }
        } catch (error) {
            const { response: { data: { msg } } } = error
            formTypePayload = { ...formTypePayload, errorMessage: msg }
            dispatch(setRequestFailure(formTypePayload))
        }
    }

    return <>
        <section className="newsletter">
            <div className="container spacer-y">
                <div className="row justify-content-center text-center">
                    <div className="col-md-7">
                        <p className="fw-600">About Us</p>
                        <h2>Keep In Touch</h2>
                        <p className="para-1 mt-2 muted-text">
                            If you need to reach out, feel free. We love hearing from our
                            readers and reading their input on how we can be better.
                        </p>
                    </div>
                    <div className="col-md-6">
                        <form className="newsletter-form mt-30" onSubmit={handleSubmit(handleFormSubmit)} noValidate >
                            <input
                                {...register("email")}
                                readOnly={aboutNewsLetter?.loader}
                                type="email"
                                name="email"
                                id=""
                                className="form-control newsletter-input"
                                placeholder="Email Address"
                            />

                            <button disabled={aboutNewsLetter?.loader}
                                type="submit"
                                className="theme-button"
                            >
                                {aboutNewsLetter?.loader ? "Please wait..." : "Subscribe"}
                            </button>
                        </form>
                        <div className="text-left mt-2">
                            {errors.email && (
                                <span style={{ color: "#CA2132", fontSize: "14px" }}>
                                    {errors.email.message}
                                </span>
                            )}
                            {Object.keys(errors).length === 0 && aboutNewsLetter?.errorMessage ? <span className="text-danger">{aboutNewsLetter?.errorMessage}</span> : ""}
                            {Object.keys(errors).length === 0 && aboutNewsLetter?.successMessage ? <span className="text-success">{aboutNewsLetter?.successMessage}</span> : ""}
                        </div>
                    </div>

                </div>
            </div>
        </section>
    </>
}

export default AboutNewsLetterForm