/* eslint-disable react-hooks/exhaustive-deps */
import "./App.css";
import "font-awesome/css/font-awesome.min.css";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Navigate, Route, Routes } from "react-router-dom";
import { Intel } from "./pages/Intel/Intel";
// import { Homepage } from "./pages/Homepage/Homepage";
import Newhomepage from "./pages/Homepage";
import BulletinsNews from "./pages/BulletinsNews";
import Podcast from "./pages/Podcast";

import { AboutPage } from "./pages/Aboutpage/AboutPage";
import { ContactPage } from "./pages/ContactPage/ContactPage";
import { SponserPage } from "./pages/SponserPage/SponserPage";
import "./App.css";
import { ResourcesPage } from "./pages/ResourcesPage/ResourcesPage";
import { IntelDesc } from "./pages/IntelDescription/IntelDesc";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { setNews, setNewsBulletin, setSponsors } from "./redux/Slices/NewsSlice";
import { Search } from "./Components/Search/Search";
import { Header } from "./Components/Header/Header";
import { Sponser } from "./Components/Sponser/Sponser";
import { Footer } from "./Components/Footer/Footer";
import { getAllNews, getNewsBulletin, getSponserList } from "./api/endpoints";
// import { Podcast } from "./pages/Podcast/Podcast";
import { PodcastDescription } from "./pages/PodcastDescription/PodcastDescription";
import PrivacyPolicy from "./pages/Policies/PrivacyPolicy";
import PageNotFound from "./pages/PageNotFound";
import { ROUTE_SLUGS } from "./constants";
import { getHeaderFooterData } from './api/endpoints'
import  { getWebsiteDataAction } from "./redux/Slices/HeaderFooterSlice";
import { he } from "date-fns/locale";

function App() {
  const dispatch = useDispatch();
  const { newsBulletin, sponsors } = useSelector((state) => state.news);

  const siteData = useSelector((state)=> state.headerFooterData)
  // const fetchData = async () => {
  //   const data = await getAllNews();
  //   dispatch(setNews(data.data));
  // };

  const fetchBulletinData = async () => {
    const data = await getNewsBulletin();
    dispatch(setNewsBulletin(data.data));
  };
  
  const fetchSponsors = async () => {
    const res = await getSponserList();
    dispatch(setSponsors(res.data.data));
  };

  useEffect(() => {
   
    if (newsBulletin?.length === 0) {
      fetchBulletinData();
    }
    
    if (sponsors?.length === 0) {
      fetchSponsors();
    }

    if(!siteData?.length){
      getHeaderFooterData().then((res)=>{
      dispatch(getWebsiteDataAction(res.data.data))
    }).catch()
  }

     }, []);

  return (
    <div className="App">
      <Header />
      <Routes>
        <Route path="/" element={<Newhomepage />} />
        {ROUTE_SLUGS.map((bulletin, INDEX) => (
          <Route
            key={INDEX}
            path={`/category/${bulletin}`}
            element={<BulletinsNews />}
          />
        ))}
        {newsBulletin.map((bulletin, INDEX) => (
          <Route
            key={INDEX}
            path={`/category/${bulletin.name.replace(' ', '-')}`}
            element={<BulletinsNews />}
          />
        ))}
        {/* <Route path="/:newsName/:id/:slug" element={<IntelDesc />} /> */}
        <Route path="/:slug" element={<IntelDesc />} />
        <Route path="/about-us" element={<AboutPage />} />
        <Route path="/category/podcast" element={<Podcast />} />
        {/* <Route path="/podcast/:id/:slug" element={<PodcastDescription />} /> */}
        <Route path="/podcast/:slug" element={<PodcastDescription />} />
        <Route path="/search" element={<Search />} />
        <Route path="/search/:title" element={<Search />} />
        <Route path="/Contact-us" element={<ContactPage />} />
        <Route path="/our-sponsors" element={<SponserPage />} />
        <Route path="/resources" element={<ResourcesPage />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="*" element={<PageNotFound />} />

        {/* {news.length !== 0 ? (
                    <>
                        <Route path="/" element={<Newhomepage />} />
                        {newsBulletin?.map((bulletin) => (
                            bulletin.name.toLowerCase() !== 'podcast'
                                ? <Route
                                    key={bulletin.id}
                                    path={`/${bulletin.name.replace(/ /g, "").toLowerCase()}`}
                                    element={<BulletinsNews />}
                                />
                                :
                                ""
                        ))}
                        <Route path="/:newsName/:id" element={<IntelDesc />} />
                        <Route path="/about" element={<AboutPage />} />
                        <Route path="/podcast" element={<Podcast />} />
                        <Route path="/podcast/:id" element={<PodcastDescription />} />
                        <Route path="/search/:title" element={<Search />} />
                        <Route path="/Contact-us" element={<ContactPage />} />
                        <Route path="/sponserDesc" element={<SponserPage />} />
                        <Route path="/resource" element={<ResourcesPage />} />
                        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                    </>
                ) : (
                    <>
                        <Route path="/" element={<Newhomepage />} />
                        {newsBulletin?.map((bulletin) => (
              <Route
                key={bulletin.id}
                path={`/${bulletin.name.replace(/ /g, "").toLowerCase()}`}
                element={<Intel />}
              />
            ))}
              </>
        )} */}
      </Routes>
      <Sponser sponsors={sponsors} />
      <Footer />
    </div>
  );
}

export default App;
