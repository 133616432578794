import { LazyLoadImage } from "react-lazy-load-image-component";

import { ImArrowUpRight2 } from "react-icons/im";
import { Link } from "react-router-dom";
import Parser from "html-react-parser";

import { img, routeName } from "../../../constants";
import { wordsCapping } from "../../../utils/helpers";
import { BsCalendarCheck } from "react-icons/bs";

function RecentNews(props) {
  const {news, bulletin} = props;
  if (news.length === 0) {
    return (
      <>
        <div
          style={{
            height: "50vh",
            position: "relative",
            display: "flex",
            right: "50px",
            width: "100%",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <span>coming soon</span>
        </div>
      </>
    );
  }

  var options = { year: "numeric", month: "long", day: "numeric" };

  const createMarkup = () => {
    return {
      __html: `${news[0].description.slice(0, 120)}${
        news[0].description.length > 120 ? "...." : ""
      }`,
    };
  };

  return (
    <>
      <section className="recent-news">
        <div className="container spacer-y">
          <h3 className="txt-cap">{bulletin.replace('-', ' ')}</h3>
          <div className="row g-3 recent-news-wrapper mt-30">
            <div className="col-lg-6">
              <div className="recent-news-post">
                <div className="recent-news-image">
                  <Link
                    to={`/${routeName(
                      news[0].news_bulletin.name,
                      news[0].id,
                      news[0].post_name
                    )}`}
                  >
                    <LazyLoadImage
                      src={`${
                        news[0]?.image_high
                          ? `${img}/${news[0]?.image_high}`
                          : "placeholder-img.png"
                      }`}
                      alt={`${news[0]?.title}`}
                      className={`${
                        news[0]?.image_high
                          ? ""
                          : "img-fluid placeholder-img w-50"
                      }`}
                    />
                  </Link>
                </div>
                <div className="news-content-wrapper mt-30">
                  <Link
                    to={`/${routeName(
                      news[0].news_bulletin.name,
                      news[0].id,
                      news[0].post_name
                    )}`}
                  >
                    <div className="recent-news-content">
                      <h4>{news.image_high}</h4>
                      <h3>{wordsCapping(news[0].title, 50)}</h3>
                      <p className="para-4  muted-text topic-detail-wrapper mt-3">
                        <span className="topic-autor-name">
                          By {news && news[0].user.name}
                        </span>
                        <span className="topic-upload-date">
                        <BsCalendarCheck className="mr-1" />
                          {new Date(news[0].published_date).toLocaleDateString(
                            "en-US",
                            options
                          )}
                        </span>
                      </p>
                      <p className="muted-text mt-2">
                        {wordsCapping(news[0].summary)}
                      </p>
                    </div>
                    <div className="news-arrow-icon">
                      <ImArrowUpRight2 />
                    </div>
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="row g-4">
                <div className="col-12">
                  {news?.slice(1, 3)?.map((news, index) => {
                    return (
                      <div
                        className="recent-news-post"
                        style={{ marginBottom: "10px" }}
                        key={index}
                      >
                        <div className="recent-news-image">
                          <Link
                            to={`/${routeName(
                              news.news_bulletin.name,
                              news.id,
                              news.post_name
                            )}`}
                          >
                            <LazyLoadImage
                              // src={`${img}/${news.image_high}`}
                              src={`${
                                news.image_high
                                  ? `${img}/${news.image_high}`
                                  : "placeholder-img.png"
                              }`}
                              className={`${
                                news.image_high
                                  ? ""
                                  : "img-fluid placeholder-img w-50"
                              }`}
                              alt={`${news.title}`}
                            />
                          </Link>
                        </div>
                        <div className="recent-news-content">
                          <Link
                            to={`/${routeName(
                              news.news_bulletin.name,
                              news.id,
                              news.post_name
                            )}`}
                          >
                            <p className="fw-600">
                              {wordsCapping(news.title, 45)}
                            </p>
                            <p className="muted-text para-3">
                              {wordsCapping(news.summary, 103)}
                            </p>
                            <p className="para-4 muted-text topic-detail-wrapper">
                              <span className="topic-autor-name">
                                By {news.user.name}
                              </span>
                              <span className="topic-upload-date">
                              <BsCalendarCheck className="mr-1" />
                                {new Date(
                                  news.published_date
                                ).toLocaleDateString("en-US", options)}
                              </span>
                            </p>
                          </Link>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default RecentNews;
