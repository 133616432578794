import React, { useEffect, useState } from "react";
import { AdLong } from "../../Components/Ad-long/AdLong";
import { Box } from "@mui/system";
import { CircularProgress } from "@mui/material";
import "../IntelDescription/IntelDesc.css";
import { useParams } from "react-router-dom";
import { date, videoPodcast, ytLink } from "../../constants";
import { getPodcastById, getPodcastBySlug } from "../../api/endpoints";
import { matchAcastUrl, acastEmbededUrl } from "../../utils/helpers";
import { BsCalendarCheck } from "react-icons/bs";
import PageNotFound from "../PageNotFound";
import GoogleAds from "../../Components/GoogleAds/Ad-long";
export const PodcastDescription = () => {
  const [loading, setloading] = useState(true);
  const [data, setdata] = useState({});
  const [is404, set404] = useState(false);
  const getData = async () => {
    setloading(true);
    try {
      const { status, data, statusCode=null } = await getPodcastBySlug(slug);
      if (status === 200) {
        if(data.statusCode=== 404){
          set404(true);
        }
        setdata(data);
      } else {
        console.warn("error case...");
      }
      setloading(false);
    } catch (error) {
      if( error?.response?.status === 404 ){
        set404(true);
      }
      setloading(false);
    }
  };

  const { id, slug } = useParams();
  useEffect(() => {
    getData();
  }, []);

  function createMarkup() {
    return { __html: data?.description };
  }

  if( is404 ){
    return <><PageNotFound /></>
  }

  return (
    <div>
      {loading ? (
        <Box
          onClick={() => setloading(false)}
          sx={{
            display: "flex",
            height: "60vh",
            alignItems: "center",
            justifyContent: "center",
            opacity: "0.8",
            zIndex: "1000",
          }}
        >
          <CircularProgress sx={{ color: "white" }} />
        </Box>
      ) : (
        <>
          <section className="intel-intro">
            <div className="container-small spacer-y">
              <div className="row justify-content-start text-left">
                <div className="col-lg-12 mb-3">
                  <h1 className="heading-md fw-600">{data?.title}</h1>
                </div>
                <div className="col-lg-10  mb-3">
                  <p className="para-1 muted-text topic-detail-wrapper">
                    <span className="topic-autor-name">
                      By {data?.user?.name}
                    </span>
                    <span className="topic-upload-date"> <BsCalendarCheck className="mr-1" /> {date(data)}</span>
                  </p>
                </div>
              </div>
            </div>
          </section>
          {data?.upload_video && (
            <div className="container-small">
              <video
                src={videoPodcast(data)}
                style={{ width: "100%", height: "500px" }}
                controls
                autoPlay
                name="media"
              ></video>
            </div>
          )}
          <div className="container-small spacer-y pt-0 p-summary">
            <p className="muted-text mb-5">{data?.summary}</p>

            <div
              className="intel-text"
              dangerouslySetInnerHTML={createMarkup()}
            />

            <div className="mt-5">
              {data?.podcast_yt_video_link &&
              data?.podcast_yt_video_link !== "null" ? (
                <iframe
                  width={"100%"}
                  height="407"
                  src={ytLink(data.podcast_yt_video_link)}
                  title="Being a CIA Secret Squirrel in a Nutshell"
                  frameborder="0"
                  allowFullScreen
                ></iframe>
              ) : (
                data?.podcast_video_link !== null && (
                  <iframe
                    width={"100%"}
                    // height="350"
                    src={
                      matchAcastUrl(data.podcast_video_link)
                        ? acastEmbededUrl(data.podcast_video_link)
                        : data.podcast_video_link
                    }
                    title="Being a CIA Secret Squirrel in a Nutshell"
                    frameborder="0"
                    allowFullScreen
                  ></iframe>
                )
              )}
            </div>
          </div>
          <GoogleAds />
        </>
      )}
    </div>
  );
};
