import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loader: false,
  errorMessage: null,
  successMessage: null,
};

export const requestSlice = createSlice({
  name: "request",
  initialState,
  reducers: {
    setRequestInProgress: (state, action) => {
      state.loader = true;
      state.successMessage = null;
      state.errorMessage = null;
    },

    setRequestSuccess: (state, action) => {
      state.successMessage = action.payload;
      state.errorMessage = null;
      state.loader = false;
    },

    setRequestFailure: (state, action) => {
      state.errorMessage = action.payload;
      state.successMessage = null;
      state.loader = false;
    },

    setRequestInit: (state, action) => {
      state.errorMessage = null;
      state.successMessage = null;
      state.loader = false;
    },
  },
});

export const {
  setRequestInProgress,
  setRequestSuccess,
  setRequestFailure,
  setRequestInit,
} = requestSlice.actions;
export default requestSlice.reducer;
